import React from 'react';
import styled from 'styled-components';

import { IconBase } from '../IconBase';
import { OrganiseIconRef, OrganiseIconProps } from './OrganiseIcon.types';

export const OrganiseIconRoot = styled(IconBase)``;

export const organiseIconClassNames = {
  root: OrganiseIconRoot.toString().slice(1),
};

export const OrganiseIcon = React.forwardRef<
  OrganiseIconRef,
  OrganiseIconProps
>(function OrganiseIcon({ className, style, size, color, ...otherProps }, ref) {
  return (
    <OrganiseIconRoot
      data-testid="OrganiseIcon"
      ref={ref}
      className={className}
      style={style}
      size={size}
      color={color}
      {...otherProps}
    >
      <path
        d="M9.99979 7.75276V1.33301M9.99979 1.33301L7.43188 3.90091M9.99979 1.33301L12.5677 3.90091M9.99979 12.2466V18.6663M9.99979 18.6663L12.5677 16.0984M9.99979 18.6663L7.43188 16.0984"
        stroke="currentColor"
      />
      <path
        d="M12.2465 9.99954L18.6663 9.99954M18.6663 9.99954L16.0984 7.43164M18.6663 9.99954L16.0984 12.5674M7.75268 9.99954L1.33293 9.99954M1.33293 9.99954L3.90083 12.5674M1.33293 9.99954L3.90083 7.43164"
        stroke="currentColor"
      />
    </OrganiseIconRoot>
  );
});
