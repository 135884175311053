import React from 'react';
import styled from 'styled-components';

import { ListRef, ListProps } from './List.types';

export const ListRoot = styled.ul`
  list-style: none;
  margin: 0;
  padding: 0;
  position: relative;
`;

export const listClassNames = {
  root: ListRoot.toString().slice(1),
};

export const List = React.forwardRef<ListRef, ListProps>(function List(
  { className, style, children, ...otherProps },
  ref,
) {
  return (
    <ListRoot
      data-testid="List"
      ref={ref}
      className={className}
      style={style}
      {...otherProps}
    >
      {children}
    </ListRoot>
  );
});
