import React from 'react';
import styled from 'styled-components';
import clsx from 'clsx';

import { useLoaded } from '../../../hooks/useLoaded';
import { ProTickTwoToneIcon } from '../../icons/ProTickTwoToneIcon';
import { AvatarProps } from './Avatar.types';

export const AvatarRoot = styled.div<Pick<AvatarProps, 'size'>>`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-shrink: 0;
  font-size: ${({ size }) => `${Math.round(size! / 2)}px`};
  line-height: 1;
  user-select: none;
  text-transform: uppercase;
  border-radius: 50%;
  background-color: var(--grey-7);
  font-weight: 700;

  border: ${({ theme }) => theme.fns.getBorder('primary')};

  ${({ theme }) => theme.fns.getTypographyStyles('primary')}

  width: ${({ size }) => `${size}px`};
  height: ${({ size }) => `${size}px`};
`;

export const AvatarImage = styled.img`
  margin: 0;
  width: 100%;
  height: 100%;
  text-align: center;
  border-radius: 50%;
  overflow: hidden;
  background: var(--grey-7);

  /* Handle non-square image. The property isn't supported by IE11. */
  object-fit: cover;
  /* Hide alt text. */
  color: transparent;
  /* Hide the image broken icon, only works on Chrome. */
  text-indent: 10000;
`;

const avatarProTickClickableClassName = 'AvatarProTick-clickable';

export const AvatarProTick = styled(ProTickTwoToneIcon)`
  position: absolute;
  top: 0;
  right: 0;
  margin-top: 14%;
  margin-right: 14%;
  transform: translate(50%, -50%);
  color: var(--common-black);
  --secondary-color: ${({ theme }) => theme.fns.getColor('common.white')};

  &.${avatarProTickClickableClassName} {
    cursor: pointer;
  }
`;

export const avatarClassNames = {
  root: AvatarRoot.toString().slice(1),
  proTick: AvatarProTick.toString().slice(1),
  proTickClickable: avatarProTickClickableClassName,
  image: AvatarImage.toString().slice(1),
};

export const Avatar = React.forwardRef<HTMLDivElement, AvatarProps>(
  function Avatar(
    {
      alt,
      children: childrenProp,
      className,
      innerProps = {},
      isPro = false,
      size = 26,
      sizes,
      src,
      srcSet,
      style,
      ...otherProps
    },
    ref,
  ) {
    // Use a hook instead of onError on the img element to support server-side rendering.
    const loaded = useLoaded({ ...innerProps.image, src, srcSet });
    const hasImage = src || srcSet;
    const hasImageNotFailing = hasImage && loaded !== 'error';

    return (
      <AvatarRoot
        className={className}
        data-testid="Avatar"
        ref={ref}
        size={size}
        style={style}
        {...otherProps}
      >
        {isPro && (
          /**
           * Calculate the size of the tick icon based on the formula
           * of this straight line
           * fontSize = (14/110)·size + 10
           * where:
           * - fontSize is the width and height of the icon
           * - size is the width and height of the avatar itself
           *
           * This formula has been chosen because it looked well when
           * the avatar size is 26 (the smallest one) and also when
           * the avatar size is 110 (the biggest one)
           */
          <AvatarProTick
            className={clsx(
              innerProps.proTick?.onClick && avatarProTickClickableClassName,
            )}
            style={{ fontSize: 10 + (14 * size) / 110 }}
            {...innerProps.proTick}
          />
        )}
        {hasImageNotFailing ? (
          <AvatarImage
            alt={alt}
            src={src}
            srcSet={srcSet}
            sizes={sizes}
            {...innerProps.image}
          />
        ) : childrenProp ? (
          childrenProp
        ) : hasImage && alt ? (
          alt[0]
        ) : null}
      </AvatarRoot>
    );
  },
);
