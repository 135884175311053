/**
 * Safe chained function.
 *
 * Will only create a new function if needed,
 * otherwise will pass back existing functions or null.
 */
export function createChainedFunction<Args extends any[], This>(
  ...funcs: Array<((this: This, ...args: Args) => any) | undefined>
): ((this: This, ...args: Args) => void) | undefined {
  return funcs.reduce((acc, func) => {
    if (!func) {
      return acc;
    }

    return function chainedFunction(...args) {
      if (acc) {
        acc.apply(this, args);
      }
      func.apply(this, args);
    };
  }, undefined);
}
