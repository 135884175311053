import * as React from 'react';
import styled from 'styled-components';

import { AccordionContext, accordionClassNames } from '../Accordion';
import {
  AccordionSummaryRef,
  AccordionSummaryProps,
} from './AccordionSummary.types';

export const AccordionSummaryRoot = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 16px 0;
  cursor: pointer;
`;

export const AccordionSummaryContent = styled.div`
  flex-grow: 1;
`;

export const AccordionSummaryExpandIconWrapper = styled.div`
  transform: rotate(0deg);
  transition: transform 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;

  .${accordionClassNames.expanded} & {
    transform: rotate(180deg);
  }
`;

export const accordionSummaryClassNames = {
  root: AccordionSummaryRoot.toString().slice(1),
  content: AccordionSummaryContent.toString().slice(1),
  expandIconWrapper: AccordionSummaryExpandIconWrapper.toString().slice(1),
};

export const AccordionSummary = React.forwardRef<
  AccordionSummaryRef,
  AccordionSummaryProps
>(function AccordionSummary(
  { id, className, style, expandIcon, onClick, children },
  ref,
) {
  const { expanded, toggle } = React.useContext(AccordionContext);

  const handleChange: React.MouseEventHandler<HTMLDivElement> = (event) => {
    if (toggle) {
      toggle(event);
    }
    if (onClick) {
      onClick(event);
    }
  };

  return (
    <AccordionSummaryRoot
      data-testid="AccordionSummary"
      ref={ref}
      id={id}
      className={className}
      style={style}
      aria-expanded={expanded}
      role="button"
      tabIndex={0}
      onClick={handleChange}
    >
      <AccordionSummaryContent>{children}</AccordionSummaryContent>
      {expandIcon && (
        <AccordionSummaryExpandIconWrapper>
          {expandIcon}
        </AccordionSummaryExpandIconWrapper>
      )}
    </AccordionSummaryRoot>
  );
});
