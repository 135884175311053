import React from 'react';
import styled from 'styled-components';

import { IconBase } from '../IconBase';
import { StarIconRef, StarIconProps } from './StarIcon.types';

export const StarIconRoot = styled(IconBase)``;

export const starIconClassNames = {
  root: StarIconRoot.toString().slice(1),
};

export const StarIcon = React.forwardRef<StarIconRef, StarIconProps>(
  function StarIcon({ className, style, size, color, ...otherProps }, ref) {
    return (
      <StarIconRoot
        data-testid="StarIcon"
        ref={ref}
        className={className}
        style={style}
        size={size}
        color={color}
        {...otherProps}
      >
        <path
          d="M18.75 8.12546H12.0312L10 1.87546L7.96875 8.12546H1.25L6.71875 11.8755L4.60937 18.1255L10 14.2192L15.3906 18.1255L13.2812 11.8755L18.75 8.12546Z"
          fill="currentColor"
          stroke="currentColor"
          strokeWidth="1.25"
          strokeLinejoin="round"
        />
      </StarIconRoot>
    );
  },
);
