import React from 'react';

import { UseEnhancedEffectHook } from './useEnhancedEffect.types';

/**
 * This hook works as a substitute of `useLayoutEffect`. It will use `useEffect`
 * when we are in SSR and `useLayoutEffect` otherwise.
 */
export const useEnhancedEffect: UseEnhancedEffectHook =
  typeof window !== 'undefined' ? React.useLayoutEffect : React.useEffect;
