import React from 'react';
import styled from 'styled-components';
import clsx from 'clsx';

import { useEnhancedEffect } from '../../../hooks/useEnhancedEffect';
import { useForkRef } from '../../../hooks/useForkRef';
import { Stack } from '../Stack';
import { CheckIcon } from '../../icons/CheckIcon';
import { ListItemIcon, listItemIconClassNames } from '../ListItemIcon';
import { typographyClassNames } from '../Typography';
import { ListItemRef, ListItemProps } from './ListItem.types';

const selectedClassName = 'ListItem-selected';
const disabledClassName = 'ListItem-disabled';

export const ListItemRoot = styled.li`
  position: relative;
  background: var(--common-white);
  cursor: pointer;

  transition: ${({ theme }) => theme.fns.getTransition(['background-color'])};

  &:hover,
  &.${selectedClassName} {
    background: var(--grey-6);
  }

  &.${selectedClassName} .${listItemIconClassNames.root}:last-child {
    justify-content: flex-end;
  }

  /**
   * TODO: Ideally we should use our ButtonBase component and pass the disabled
   * prop to it. In the MUI library they have two different components:
   * ListItem to convey a list of items, and ListItemButton to create an
   * interactive list of items you can click on.
   */
  &.${disabledClassName} {
    background: var(--grey-5);
    cursor: not-allowed;

    .${typographyClassNames.root} {
      color: var(--grey-2);
    }
  }
`;

export const ListItemItem = styled(Stack).attrs({
  direction: 'row',
  justifyContent: 'center',
})`
  position: relative;
  text-decoration: none;
  width: 100%;
  box-sizing: border-box;
  text-align: left;
  padding: 12px;
`;

export const listItemClassNames = {
  root: ListItemRoot.toString().slice(1),
  selected: selectedClassName,
  disabled: disabledClassName,
  container: ListItemItem.toString().slice(1),
};

export const ListItem = React.forwardRef<ListItemRef, ListItemProps>(
  function ListItem(
    {
      className,
      style,
      alignItems = 'center',
      autoFocus = false,
      disabled = false,
      selected = false,
      onClick,
      children,
    },
    ref,
  ) {
    const listItemRef = React.useRef<HTMLLIElement>(null);

    useEnhancedEffect(() => {
      if (autoFocus) {
        listItemRef.current?.focus();
      }
    }, [autoFocus]);

    const handleRef = useForkRef(listItemRef, ref);

    return (
      <ListItemRoot
        data-testid="ListItem"
        ref={handleRef}
        className={clsx(
          className,
          selected && selectedClassName,
          disabled && disabledClassName,
        )}
        style={style}
        onClick={onClick}
      >
        <ListItemItem alignItems={alignItems}>
          {children}
          {selected && (
            <ListItemIcon>
              <CheckIcon />
            </ListItemIcon>
          )}
        </ListItemItem>
      </ListItemRoot>
    );
  },
);
