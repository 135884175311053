import React from 'react';
import styled from 'styled-components';

import { IconBase } from '../IconBase';
import {
  RemoveCircleIconRef,
  RemoveCircleIconProps,
} from './RemoveCircleIcon.types';

export const RemoveCircleIconRoot = styled(IconBase)``;

export const removeCircleIconClassNames = {
  root: RemoveCircleIconRoot.toString().slice(1),
};

export const RemoveCircleIcon = React.forwardRef<
  RemoveCircleIconRef,
  RemoveCircleIconProps
>(function RemoveCircleIcon(
  { className, style, size, color, ...otherProps },
  ref,
) {
  return (
    <RemoveCircleIconRoot
      data-testid="RemoveCircleIcon"
      ref={ref}
      className={className}
      style={style}
      size={size}
      color={color}
      {...otherProps}
    >
      <circle cx="10" cy="10" r="9.5" fill="white" stroke="currentColor" />
      <path
        d="M5.91602 10H14.0827"
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <circle cx="10" cy="10" r="9.5" stroke="currentColor" />
    </RemoveCircleIconRoot>
  );
});
