import React from 'react';
import styled from 'styled-components';
import clsx from 'clsx';

import { cssBaselineClassNames } from '../CssBaseline';
import { ButtonBase, buttonBaseClassNames } from '../ButtonBase';
import { StandardButtonRef, StandardButtonProps } from './StandardButton.types';

const variantPrimaryBlackClassName = 'StandardButton-variantPrimaryBlack';
const variantPrimaryWhiteClassName = 'StandardButton-variantPrimaryWhite';
const variantSecondaryClassName = 'StandardButton-variantSecondary';

export const StandardButtonRoot = styled(ButtonBase)`
  ${({ theme }) => theme.fns.getTypographyStyles('primary.b14')}
  ${({ theme }) => theme.fns.getShapeStyles('main')}

  &.${buttonBaseClassNames.withIcons}:not(.${cssBaselineClassNames.fullWidth}) {
    ${({ theme }) => theme.fns.getTypographyStyles('primary.r14')}
  }

  &.${variantPrimaryBlackClassName}:not(.${buttonBaseClassNames.disabled}),
  &.${variantPrimaryWhiteClassName}:not(.${buttonBaseClassNames.disabled}) {
    border: ${({ theme }) => theme.fns.getBorder('primary')};
    box-shadow: ${({ theme }) => theme.shadows[1]};

    &:hover {
      box-shadow: ${({ theme }) => theme.shadows[2]};
    }

    &:active {
      box-shadow: ${({ theme }) => theme.shadows[0]};
    }
  }

  &.${variantSecondaryClassName}:not(.${buttonBaseClassNames.disabled}) {
    border: ${({ theme }) => theme.fns.getBorder('secondary')};

    &:hover {
      background: ${({ theme }) => theme.fns.getColor('grey.6')};
    }
  }
`;

export const standardButtonClassNames = {
  root: StandardButtonRoot.toString().slice(1),
  variantPrimaryBlack: variantPrimaryBlackClassName,
  variantPrimaryWhite: variantPrimaryWhiteClassName,
  variantSecondary: variantSecondaryClassName,
};

export const standardButtonMapVariantToClassName = {
  'primary-black': standardButtonClassNames.variantPrimaryBlack,
  'primary-white': standardButtonClassNames.variantPrimaryWhite,
  'secondary': standardButtonClassNames.variantSecondary,
};

export const StandardButton = React.forwardRef<
  StandardButtonRef,
  StandardButtonProps
>(function StandardButton(
  {
    className,
    style,
    type = 'button',
    variant = 'primary-black',
    fullWidth = false,
    disabled = false,
    loading = false,
    startIcon,
    endIcon,
    component,
    to,
    hrefLang,
    rel,
    target,
    onClick,
    children,
  },
  ref,
) {
  return (
    <StandardButtonRoot
      data-testid="StandardButton"
      ref={ref}
      className={clsx(
        variant && standardButtonMapVariantToClassName[variant],
        className,
      )}
      style={style}
      color={variant === 'primary-black' ? 'common.black' : 'common.white'}
      fullWidth={fullWidth}
      disabled={disabled}
      loading={loading}
      startIcon={startIcon}
      endIcon={endIcon}
      type={type}
      component={component}
      to={to}
      hrefLang={hrefLang}
      rel={rel}
      target={target}
      onClick={onClick}
    >
      {children}
    </StandardButtonRoot>
  );
});
