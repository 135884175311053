import React from 'react';
import styled from 'styled-components';

import { IconBase } from '../IconBase';
import { LaunchIconRef, LaunchIconProps } from './LaunchIcon.types';

export const LaunchIconRoot = styled(IconBase)``;

export const launchIconClassNames = {
  root: LaunchIconRoot.toString().slice(1),
};

export const LaunchIcon = React.forwardRef<LaunchIconRef, LaunchIconProps>(
  function LaunchIcon({ className, style, size, color, ...otherProps }, ref) {
    return (
      <LaunchIconRoot
        data-testid="LaunchIcon"
        ref={ref}
        className={className}
        style={style}
        size={size}
        color={color}
        {...otherProps}
      >
        <path
          d="M17.5 2.1875H12.5M17.5 2.1875V7.1875M17.5 2.1875L8.125 11.5625M8.75 4.0625H5C3.61929 4.0625 2.5 5.18179 2.5 6.5625V15.3125C2.5 16.6932 3.61929 17.8125 5 17.8125H13.75C15.1307 17.8125 16.25 16.6932 16.25 15.3125V10.9375"
          strokeWidth="1.25"
          strokeLinecap="round"
          strokeLinejoin="round"
          stroke="currentColor"
        />
      </LaunchIconRoot>
    );
  },
);
