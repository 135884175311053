import React from 'react';
import styled from 'styled-components';

import { IconBase } from '../IconBase';
import { CheckIconRef, CheckIconProps } from './CheckIcon.types';

export const CheckIconRoot = styled(IconBase)``;

export const checkIconClassNames = {
  root: CheckIconRoot.toString().slice(1),
};

export const CheckIcon = React.forwardRef<CheckIconRef, CheckIconProps>(
  function CheckIcon({ className, style, size, color, ...otherProps }, ref) {
    return (
      <CheckIconRoot
        data-testid="CheckIcon"
        ref={ref}
        className={className}
        style={style}
        size={size}
        color={color}
        {...otherProps}
      >
        <path
          d="M16.25 5L7.5 15L3.75 11.25"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
          stroke="currentColor"
        />
      </CheckIconRoot>
    );
  },
);
