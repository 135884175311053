import React from 'react';
import styled from 'styled-components';
import clsx from 'clsx';

import { BaseProps } from './Base.types';

const sizeSmallClassName = 'Base-s';
const sizeMediumClassName = 'Base-m';
const sizeLargeClassName = 'Base-l';
const sizeLargerClassName = 'Base-xl';

export const BaseRoot = styled.div<Pick<BaseProps, 'secondaryColor' | 'size'>>`
  color: ${({ theme, color }) => theme.fns.getColor(color)};
  --secondary-color: ${({ theme, secondaryColor }) =>
    theme.fns.getColor(secondaryColor)};

  width: ${(props) => (props.size != null ? `${props.size}px` : '1em')};
  height: ${(props) => (props.size != null ? `${props.size}px` : '1em')};

  & svg {
    width: 100%;
    height: auto;
  }

  &.${sizeSmallClassName} {
    width: 16px;
    height: 16px;
  }

  &.${sizeMediumClassName} {
    width: 20px;
    height: 20px;
  }

  &.${sizeLargeClassName} {
    width: 24px;
    height: 24px;
  }

  &.${sizeLargerClassName} {
    width: 28px;
    height: 28px;
  }
`;

export const baseClassNames = {
  root: BaseRoot.toString().slice(1),
  s: sizeSmallClassName,
  m: sizeMediumClassName,
  l: sizeLargeClassName,
  xl: sizeLargerClassName,
};

export const baseMapSizeToClassName = {
  s: sizeSmallClassName,
  m: sizeMediumClassName,
  l: sizeLargeClassName,
  xl: sizeLargerClassName,
};

export const Base: React.FC<BaseProps> = ({
  className,
  size = 'm',
  interactive = true,
  children,
  ...restProps
}) => {
  const mappedClass =
    typeof size === 'string' ? baseMapSizeToClassName[size] : null;

  return (
    <BaseRoot
      className={clsx(mappedClass, className)}
      size={typeof size === 'number' ? size : undefined}
      aria-hidden={!interactive}
      {...restProps}
    >
      {children}
    </BaseRoot>
  );
};
