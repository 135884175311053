import React from 'react';
import styled from 'styled-components';
import clsx from 'clsx';

import { LoadingMoreIcon } from '../../icons/LoadingMoreIcon';
import { ButtonBase, buttonBaseClassNames } from '../ButtonBase';
import {
  EditImageListButtonRef,
  EditImageListButtonProps,
} from './EditImageListButton.types';

export const EditImageListButtonRoot = styled(ButtonBase)`
  min-width: 28px;
  width: 30px;
  height: 30px;
  padding: 0;
  color: var(--common-black);
  border: ${({ theme }) => theme.fns.getBorder('secondary')};

  ${({ theme }) => theme.fns.getShapeStyles('round')}

  &:not(.${buttonBaseClassNames.disabled}) {
    &:hover {
      background: var(--grey-6);
    }

    &:active {
      background: var(--grey-5);
    }
  }
`;

export const editImageListButtonClassNames = {
  root: EditImageListButtonRoot.toString().slice(1),
  loading: 'EditImageListButton-loading',
};

export const EditImageListButton = React.forwardRef<
  EditImageListButtonRef,
  EditImageListButtonProps
>(function EditImageListButton(
  {
    className,
    style,
    disabled = false,
    loading = false,
    type = 'button',
    onClick,
    children,
  },
  ref,
) {
  return (
    <EditImageListButtonRoot
      data-testid="EditImageListButton"
      ref={ref}
      className={clsx(
        loading && editImageListButtonClassNames.loading,
        className,
      )}
      style={style}
      loading={loading}
      loadingIcon={<LoadingMoreIcon />}
      fullWidth={false}
      color="common.white"
      disabled={disabled}
      type={type}
      onClick={onClick}
    >
      {children}
    </EditImageListButtonRoot>
  );
});
