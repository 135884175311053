import React from 'react';
import styled from 'styled-components';

import { IconBase } from '../IconBase';
import {
  BookmarkTwoToneIconRef,
  BookmarkTwoToneIconProps,
} from './BookmarkTwoToneIcon.types';

export const BookmarkTwoToneIconRoot = styled(IconBase)``;

export const bookmarkTwoToneIconClassNames = {
  root: BookmarkTwoToneIconRoot.toString().slice(1),
};

export const BookmarkTwoToneIcon = React.forwardRef<
  BookmarkTwoToneIconRef,
  BookmarkTwoToneIconProps
>(function BookmarkTwoToneIcon(
  { className, style, size, color, secondaryColor, ...otherProps },
  ref,
) {
  return (
    <BookmarkTwoToneIconRoot
      data-testid="BookmarkTwoToneIcon"
      ref={ref}
      className={className}
      style={style}
      size={size}
      color={color}
      secondaryColor={secondaryColor}
      {...otherProps}
    >
      <path
        d="M3 18V2H17V18L10 10.967L3 18Z"
        strokeLinecap="round"
        strokeLinejoin="round"
        stroke="currentColor"
        fill="var(--secondary-color)"
      />
    </BookmarkTwoToneIconRoot>
  );
});
