import React from 'react';
import styled from 'styled-components';

import { IconBase } from '../IconBase';
import { RefreshIconRef, RefreshIconProps } from './RefreshIcon.types';

export const RefreshIconRoot = styled(IconBase)``;

export const refreshIconClassNames = {
  root: RefreshIconRoot.toString().slice(1),
};

export const RefreshIcon = React.forwardRef<RefreshIconRef, RefreshIconProps>(
  function RefreshIcon({ className, style, size, color, ...otherProps }, ref) {
    return (
      <RefreshIconRoot
        data-testid="RefreshIcon"
        ref={ref}
        className={className}
        style={style}
        size={size}
        color={color}
        {...otherProps}
      >
        <path
          d="M15.9492 5.78126L15.1242 4.82149C14.4247 4.08679 13.583 3.5021 12.6504 3.103C11.7178 2.7039 10.7137 2.49874 9.69922 2.50001C5.55859 2.50001 2.19922 5.85938 2.19922 10C2.19922 14.1406 5.55859 17.5 9.69922 17.5C11.2504 17.4999 12.7634 17.0191 14.0301 16.1237C15.2967 15.2283 16.2547 13.9623 16.7723 12.5"
          stroke="currentColor"
          strokeWidth="1.25"
          strokeMiterlimit="10"
          strokeLinecap="round"
        />
        <path
          d="M18.4499 3.80653V8.12606C18.4499 8.29182 18.3841 8.45079 18.2669 8.568C18.1497 8.68521 17.9907 8.75106 17.8249 8.75106H13.5054C12.9484 8.75106 12.6695 8.07801 13.0632 7.68426L17.3831 3.36434C17.7769 2.96981 18.4499 3.2495 18.4499 3.80653Z"
          fill="currentColor"
        />
      </RefreshIconRoot>
    );
  },
);
