import React from 'react';
import styled from 'styled-components';

import { IconBase } from '../IconBase';
import {
  LockClosedOutlinedIconRef,
  LockClosedOutlinedIconProps,
} from './LockClosedOutlinedIcon.types';

export const LockClosedOutlinedIconRoot = styled(IconBase)``;

export const lockClosedOutlinedIconClassNames = {
  root: LockClosedOutlinedIconRoot.toString().slice(1),
};

export const LockClosedOutlinedIcon = React.forwardRef<
  LockClosedOutlinedIconRef,
  LockClosedOutlinedIconProps
>(function LockClosedOutlinedIcon(
  { className, style, size, color, ...otherProps },
  ref,
) {
  return (
    <LockClosedOutlinedIconRoot
      data-testid="LockClosedOutlinedIcon"
      ref={ref}
      className={className}
      style={style}
      size={size}
      color={color}
      {...otherProps}
    >
      <path
        d="M13.125 8.12499V4.41406C13.125 3.58526 12.7958 2.7904 12.2097 2.20435C11.6237 1.6183 10.8288 1.28906 10 1.28906C9.1712 1.28906 8.37634 1.6183 7.79029 2.20435C7.20424 2.7904 6.875 3.58526 6.875 4.41406V8.12499"
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M14.3752 8.125H5.62524C4.58971 8.125 3.75024 8.96447 3.75024 10V16.875C3.75024 17.9105 4.58971 18.75 5.62524 18.75H14.3752C15.4108 18.75 16.2502 17.9105 16.2502 16.875V10C16.2502 8.96447 15.4108 8.125 14.3752 8.125Z"
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </LockClosedOutlinedIconRoot>
  );
});
