import React from 'react';
import styled from 'styled-components';
import clsx from 'clsx';

import { IconBaseRef, IconBaseProps } from './IconBase.types';

const sizeSmallClassName = 'IconBase-sizeSmall';
const sizeMediumClassName = 'IconBase-sizeMedium';
const sizeLargeClassName = 'IconBase-sizeLarge';
const sizeLargerClassName = 'IconBase-sizeLarger';

export const IconBaseRoot = styled.svg<Pick<IconBaseProps, 'secondaryColor'>>`
  width: 1em;
  height: 1em;

  color: ${({ theme, color }) => theme.fns.getColor(color)};

  --secondary-color: ${({ theme, secondaryColor }) =>
    theme.fns.getColor(secondaryColor)};

  &.${sizeSmallClassName} {
    font-size: 16px;
  }

  &.${sizeMediumClassName} {
    font-size: 20px;
  }

  &.${sizeLargeClassName} {
    font-size: 24px;
  }

  &.${sizeLargerClassName} {
    font-size: 28px;
  }
`;

export const iconBaseClassNames = {
  root: IconBaseRoot.toString().slice(1),
  sizeSmall: sizeSmallClassName,
  sizeMedium: sizeMediumClassName,
  sizeLarge: sizeLargeClassName,
  sizeLarger: sizeLargerClassName,
};

export const iconBaseMapSizeToClassName = {
  small: sizeSmallClassName,
  medium: sizeMediumClassName,
  large: sizeLargeClassName,
  larger: sizeLargerClassName,
};

export const IconBase = React.forwardRef<IconBaseRef, IconBaseProps>(
  function IconBase(
    { className, style, size = 'medium', children, ...otherProps },
    ref,
  ) {
    return (
      <IconBaseRoot
        ref={ref}
        className={clsx(iconBaseMapSizeToClassName[size], className)}
        style={style}
        aria-hidden={true}
        focusable={false}
        viewBox="0 0 20 20"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        {...otherProps}
      >
        {children}
      </IconBaseRoot>
    );
  },
);
