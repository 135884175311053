import React from 'react';
import styled from 'styled-components';
import clsx from 'clsx';

import { LoadingDots } from '../LoadingDots';
import { ButtonBase, buttonBaseClassNames } from '../ButtonBase';
import { cssBaselineClassNames } from '../CssBaseline';
import { toggleButtonGroupClassNames } from '../ToggleButtonGroup';
import { ToggleButtonRef, ToggleButtonProps } from './ToggleButton.types';

const selectedClassName = 'ToggleButton-selected';

export const ToggleButtonRoot = styled(ButtonBase)`
  ${({ theme }) => theme.fns.getTypographyStyles('primary.r14')}
  ${({ theme }) => theme.fns.getShapeStyles('soft')}
  border: ${({ theme }) => theme.fns.getBorder('primary')};

  &.${selectedClassName}:not(.${buttonBaseClassNames.disabled}) {
    box-shadow: ${({ theme }) => theme.shadows[1]};

    &:hover {
      box-shadow: ${({ theme }) => theme.shadows[2]};
    }

    &:active {
      box-shadow: ${({ theme }) => theme.shadows[0]};
    }
  }

  &:not(.${selectedClassName}, .${buttonBaseClassNames.disabled}) {
    background: var(--grey-7);
    color: var(--common-black);
    border: ${({ theme }) => theme.fns.getBorder('secondary')};
  }

  .${toggleButtonGroupClassNames.root}.${cssBaselineClassNames.fullWidth} & {
    flex-grow: 1;
  }

  .${toggleButtonGroupClassNames.root} &.${selectedClassName} {
    margin-top: -1px;
    margin-bottom: -1px;

    &:first-of-type {
      margin-left: -1px;
    }

    &:last-of-type {
      margin-right: -1px;
    }
  }

  .${toggleButtonGroupClassNames.root} &:not(.${selectedClassName}) {
    border: 0;

    &:first-of-type {
      border-top-right-radius: 0;
      border-bottom-right-radius: 0;
    }

    &:last-of-type {
      border-top-left-radius: 0;
      border-bottom-left-radius: 0;
    }
  }
`;

export const toggleButtonClassNames = {
  root: ToggleButtonRoot.toString().slice(1),
  selected: selectedClassName,
};

export const ToggleButton = React.forwardRef<
  ToggleButtonRef,
  ToggleButtonProps
>(function ToggleButton(
  {
    className,
    style,
    color = 'common.black',
    disabled = false,
    fullWidth = false,
    loading = false,
    tabIndex,
    loadingIcon = <LoadingDots />,
    startIcon,
    endIcon,
    selected = false,
    value,
    onClick,
    onChange,
    onMouseEnter,
    onMouseLeave,
    children,
  },
  ref,
) {
  // If provided, calls both `onClick` and `onChange` handlers with the event
  // as the first parameter and the provided `value` as the second.
  // If, for some reason, we only want to call the `onClick` handler, we can
  // provide an `onClick` handler that calls `event.preventDefault()`. This will
  // avoid calling the `onChange` handler too.
  const handleChange = (event: React.MouseEvent<HTMLElement, MouseEvent>) => {
    if (onClick) {
      onClick(event, value);
      if (event.defaultPrevented) {
        return;
      }
    }

    if (onChange) {
      onChange(event, value);
    }
  };

  return (
    <ToggleButtonRoot
      data-testid="ToggleButton"
      ref={ref}
      className={clsx(className, selected && selectedClassName)}
      style={style}
      color={color}
      disabled={disabled}
      fullWidth={fullWidth}
      loading={loading}
      tabIndex={tabIndex}
      aria-pressed={selected}
      loadingIcon={loadingIcon}
      startIcon={startIcon}
      endIcon={endIcon}
      onClick={handleChange}
      onChange={
        onChange as unknown as
          | React.ChangeEventHandler<HTMLButtonElement>
          | undefined
      }
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
    >
      {children}
    </ToggleButtonRoot>
  );
});
