import React from 'react';
import styled from 'styled-components';

import { IconBase } from '../IconBase';
import { CloseIconRef, CloseIconProps } from './CloseIcon.types';

export const CloseIconRoot = styled(IconBase)``;

export const closeIconClassNames = {
  root: CloseIconRoot.toString().slice(1),
};

export const CloseIcon = React.forwardRef<CloseIconRef, CloseIconProps>(
  function CloseIcon({ className, style, size, color, ...otherProps }, ref) {
    return (
      <CloseIconRoot
        data-testid="CloseIcon"
        ref={ref}
        className={className}
        style={style}
        size={size}
        color={color}
        {...otherProps}
      >
        <path
          d="M17.75 17.75L2 2"
          stroke="currentColor"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M17.75 2L2 17.75"
          stroke="currentColor"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </CloseIconRoot>
    );
  },
);
