import React from 'react';

import { Base } from '../Base';
import type { BaseProps } from '../Base';

export const LorryIcon = (props: BaseProps) => {
  return (
    <Base data-testid={props['data-testid'] ?? 'LorryIcon'} {...props}>
      <svg
        width="20"
        height="20"
        viewBox="0 0 20 20"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g clipPath="url(#clip0_2582_15722)">
          <path
            d="M18.6654 10.5007V13.6673H17.4987H16.9987V14.1673C16.9987 15.2745 16.1059 16.1673 14.9987 16.1673C13.8915 16.1673 12.9987 15.2745 12.9987 14.1673V13.6673H12.4987H7.4987H6.9987V14.1673C6.9987 15.2745 6.10589 16.1673 4.9987 16.1673C3.89151 16.1673 2.9987 15.2745 2.9987 14.1673V13.6673H2.4987H1.33203V5.00065C1.33203 4.36013 1.85817 3.83398 2.4987 3.83398H13.6654V6.66732V7.16732H14.1654H16.4154L18.6654 10.1673V10.1894L18.2755 9.69216L16.6422 7.60882L16.492 7.41732H16.2487H14.1654H13.6654V7.91732V10.0007V10.5007H14.1654H17.882H18.6654ZM3.2487 14.1673C3.2487 15.1351 4.03089 15.9173 4.9987 15.9173C5.96651 15.9173 6.7487 15.1351 6.7487 14.1673C6.7487 13.1995 5.96651 12.4173 4.9987 12.4173C4.03089 12.4173 3.2487 13.1995 3.2487 14.1673ZM13.2487 14.1673C13.2487 15.1351 14.0309 15.9173 14.9987 15.9173C15.9665 15.9173 16.7487 15.1351 16.7487 14.1673C16.7487 13.1995 15.9665 12.4173 14.9987 12.4173C14.0309 12.4173 13.2487 13.1995 13.2487 14.1673Z"
            fill="white"
            stroke="currentColor"
          />
        </g>
        <defs>
          <clipPath id="clip0_2582_15722">
            <rect width="20" height="20" fill="white" />
          </clipPath>
        </defs>
      </svg>
    </Base>
  );
};
