import * as React from 'react';

let globalId = 0;
function useGlobalId(idOverride?: string): string | undefined {
  const [defaultId, setDefaultId] = React.useState(idOverride);
  const id = idOverride || defaultId;
  React.useEffect(() => {
    if (defaultId == null) {
      // Fallback to this default id when possible.
      // Use the incrementing value for client-side rendering only.
      // We can't use it server-side.
      // If you want to use random values please consider the Birthday Problem: https://en.wikipedia.org/wiki/Birthday_problem
      globalId += 1;
      setDefaultId(`moonsift-${globalId}`);
    }
  }, [defaultId]);
  return id;
}

// eslint-disable-next-line no-useless-concat -- Workaround for https://github.com/webpack/webpack/issues/14814
const maybeReactUseId: undefined | (() => string) = (React as any)[
  'useId' + ''
];

/**
 * Provides an ID to be used by UI components to which and `id` prop has not
 * been provided. It will use React's own API `useId` if we are in React 18 or
 * a custome `useGlobalId` which will provide an ID from a counter. The latter
 * won't keep consistency between CSR and SSR.
 *
 * Copied from
 * https://github.com/mui/material-ui/blob/62a777e6ea4dd13c5e9f369b6c843a389b160dda/packages/mui-utils/src/useId.ts
 */
export function useId(idOverride?: string): string | undefined {
  if (maybeReactUseId !== undefined) {
    const reactId = maybeReactUseId();
    return idOverride ?? reactId;
  }
  // eslint-disable-next-line react-hooks/rules-of-hooks -- `React.useId` is invariant at runtime.
  return useGlobalId(idOverride);
}
