import React from 'react';
import styled from 'styled-components';

import { IconBase } from '../IconBase';
import { BookmarkIconRef, BookmarkIconProps } from './BookmarkIcon.types';

export const BookmarkIconRoot = styled(IconBase)``;

export const bookmarkIconClassNames = {
  root: BookmarkIconRoot.toString().slice(1),
};

export const BookmarkIcon = React.forwardRef<
  BookmarkIconRef,
  BookmarkIconProps
>(function BookmarkIcon({ className, style, size, color, ...otherProps }, ref) {
  return (
    <BookmarkIconRoot
      data-testid="BookmarkIcon"
      ref={ref}
      className={className}
      style={style}
      size={size}
      color={color}
      {...otherProps}
    >
      <path
        d="M3 18V2H17V18L10 10.967L3 18Z"
        strokeLinecap="round"
        strokeLinejoin="round"
        stroke="currentColor"
      />
    </BookmarkIconRoot>
  );
});
