import React, { forwardRef } from 'react';
import { useField } from 'formik';

import { TextFieldRef, ControlledTextFieldProps } from './TextField.types';
import { TextField } from './TextField.component';

export const ControlledTextField = forwardRef<
  TextFieldRef,
  ControlledTextFieldProps
>(function ControlledTextField(
  {
    id,
    name: nameProp,
    className,
    style,
    autoComplete,
    autoFocus = false,
    disabled = false,
    fullWidth,
    maxLength,
    multiline,
    placeholder,
    required,
    rows,
    minRows,
    maxRows,
    type = 'text',
    value: valueProp,
    inputRef,
    onChange: onChangeProp,
    onCopy,
    onPaste,
    onDrop,
  },
  ref,
) {
  const [{ name, onChange, value }, meta] = useField({
    id,
    name: nameProp,
    className,
    style,
    autoComplete,
    autoFocus,
    disabled,
    maxLength,
    placeholder,
    required,
    rows,
    type,
    value: valueProp,
    ref: inputRef,
    onChange: onChangeProp,
  });

  // Get text helper and error state from props Formik state
  const helperText = meta.touched ? meta.error : undefined;
  const error = Boolean(helperText);

  return (
    <TextField
      ref={ref}
      id={id}
      name={name}
      className={className}
      style={style}
      autoComplete={autoComplete}
      autoFocus={autoFocus}
      disabled={disabled}
      error={error}
      fullWidth={fullWidth}
      helperText={helperText}
      maxLength={maxLength}
      multiline={multiline}
      placeholder={placeholder}
      required={required}
      rows={rows}
      minRows={minRows}
      maxRows={maxRows}
      type={type}
      value={value}
      inputRef={inputRef}
      onChange={onChange}
      onCopy={onCopy}
      onPaste={onPaste}
      onDrop={onDrop}
    />
  );
});
