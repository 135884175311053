import React from 'react';
import styled from 'styled-components';
import clsx from 'clsx';

import { cssBaselineClassNames } from '../CssBaseline';
import { ButtonBase, buttonBaseClassNames } from '../ButtonBase';
import { CtaButtonRef, CtaButtonProps } from './CtaButton.types';

const sizeSmallClassName = 'CtaButton-sizeSmall';
const sizeMediumClassName = 'CtaButton-sizeMedium';
const sizeLargeClassName = 'CtaButton-sizeLarge';
const variantPrimaryBlackClassName = 'CtaButton-variantPrimaryBlack';
const variantPrimaryWhiteClassName = 'CtaButton-variantPrimaryWhite';
const variantPrimaryWhiteGreyClassName = 'CtaButton-variantPrimaryWhiteGrey';
const variantSecondaryClassName = 'CtaButton-variantSecondary';

export const CtaButtonRoot = styled(ButtonBase)`
  ${({ theme }) => theme.fns.getShapeStyles('cta')}

  &.${sizeSmallClassName} {
    height: 36px;

    ${({ theme }) => theme.fns.getTypographyStyles('primary.b14')}

    &.${variantPrimaryWhiteGreyClassName},
    &.${buttonBaseClassNames.withIcons}:not(.${cssBaselineClassNames.fullWidth}) {
      ${({ theme }) => theme.fns.getTypographyStyles('primary.r14')}
    }
  }

  &.${sizeMediumClassName} {
    height: 42px;

    ${({ theme }) => theme.fns.getTypographyStyles('primary.b18')}

    &.${variantPrimaryWhiteGreyClassName},
    &.${buttonBaseClassNames.withIcons}:not(.${cssBaselineClassNames.fullWidth}) {
      ${({ theme }) => theme.fns.getTypographyStyles('primary.r18')}
    }
  }

  &.${sizeLargeClassName} {
    height: 54px;

    ${({ theme }) => theme.fns.getTypographyStyles('primary.b26')}

    &.${variantPrimaryWhiteGreyClassName},
    &.${buttonBaseClassNames.withIcons}:not(.${cssBaselineClassNames.fullWidth}) {
      ${({ theme }) => theme.fns.getTypographyStyles('primary.r26')}
    }
  }

  &.${variantPrimaryBlackClassName}:not(.${buttonBaseClassNames.disabled}),
  &.${variantPrimaryWhiteClassName}:not(.${buttonBaseClassNames.disabled}),
  &.${variantPrimaryWhiteGreyClassName}:not(.${buttonBaseClassNames.disabled}) {
    border: ${({ theme }) => theme.fns.getBorder('cta-primary')};
    box-shadow: ${({ theme }) => theme.shadows[1]};

    &:hover {
      box-shadow: ${({ theme }) => theme.shadows[2]};
    }

    &:active {
      box-shadow: ${({ theme }) => theme.shadows[0]};
    }
  }

  &.${variantPrimaryWhiteGreyClassName}:not(.${buttonBaseClassNames.disabled}) {
    border: ${({ theme }) => theme.fns.getBorder('secondary')};
  }

  &.${variantPrimaryBlackClassName}:not(.${buttonBaseClassNames.disabled}):hover {
    background: ${({ theme }) => theme.fns.getColor('grey.1')};
  }

  &.${variantPrimaryBlackClassName}:not(.${buttonBaseClassNames.disabled}):active {
    background: ${({ theme }) => theme.fns.getColor('common.black')};
    border: ${({ theme }) => theme.fns.getBorder('cta-primary.active')};
  }

  &.${variantSecondaryClassName}:not(.${buttonBaseClassNames.disabled}) {
    border: ${({ theme }) => theme.fns.getBorder('cta-secondary')};
    color: ${({ theme }) => theme.fns.getColor('common.white')};
    background: transparent;

    &:hover {
      border: ${({ theme }) => theme.fns.getBorder('cta-secondary.hover')};
      color: ${({ theme }) => theme.fns.getColor('common.black')};
      background: ${({ theme }) => theme.fns.getColor('common.white')};
    }
  }
`;

export const ctaButtonClassNames = {
  sizeSmall: sizeSmallClassName,
  sizeMedium: sizeMediumClassName,
  sizeLarge: sizeLargeClassName,
  variantPrimaryBlack: variantPrimaryBlackClassName,
  variantPrimaryWhite: variantPrimaryWhiteClassName,
  variantPrimaryWhiteGrey: variantPrimaryWhiteGreyClassName,
  variantSecondary: variantSecondaryClassName,
};

export const ctaButtonMapSizeToClassName = {
  small: sizeSmallClassName,
  medium: sizeMediumClassName,
  large: sizeLargeClassName,
};

export const ctaButtonMapVariantToClassName = {
  'primary-black': variantPrimaryBlackClassName,
  'primary-white': variantPrimaryWhiteClassName,
  'primary-white-grey': variantPrimaryWhiteGreyClassName,
  'secondary': variantSecondaryClassName,
};

export const CtaButton = React.forwardRef<CtaButtonRef, CtaButtonProps>(
  function CtaButton(
    {
      className,
      style,
      variant = 'primary-black',
      size = 'medium',
      fullWidth = false,
      disabled = false,
      loading = false,
      type = 'button',
      startIcon,
      endIcon,
      component,
      to,
      hrefLang,
      rel,
      target,
      onClick,
      children,
    },
    ref,
  ) {
    return (
      <CtaButtonRoot
        data-testid="CtaButton"
        ref={ref}
        className={clsx(
          ctaButtonMapSizeToClassName[size],
          ctaButtonMapVariantToClassName[variant],
          className,
        )}
        style={style}
        color={variant === 'primary-black' ? 'common.black' : 'common.white'}
        fullWidth={fullWidth}
        disabled={disabled}
        loading={loading}
        startIcon={startIcon}
        endIcon={endIcon}
        type={type}
        component={component}
        to={to}
        hrefLang={hrefLang}
        rel={rel}
        target={target}
        onClick={onClick}
      >
        {children}
      </CtaButtonRoot>
    );
  },
);
