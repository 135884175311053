import React from 'react';
import styled from 'styled-components';

import { IconBase } from '../IconBase';
import {
  CheckCircleOutlinedIconRef,
  CheckCircleOutlinedIconProps,
} from './CheckCircleOutlinedIcon.types';

export const CheckCircleOutlinedIconRoot = styled(IconBase)``;

export const checkCircleOutlinedIconClassNames = {
  root: CheckCircleOutlinedIconRoot.toString().slice(1),
};

export const CheckCircleOutlinedIcon = React.forwardRef<
  CheckCircleOutlinedIconRef,
  CheckCircleOutlinedIconProps
>(function CheckCircleOutlinedIcon(
  { className, style, size, color, ...otherProps },
  ref,
) {
  return (
    <CheckCircleOutlinedIconRoot
      data-testid="CheckCircleOutlinedIcon"
      ref={ref}
      className={className}
      style={style}
      size={size}
      color={color}
      {...otherProps}
    >
      <path
        d="M17.5 10C17.5 5.85937 14.1406 2.5 10 2.5C5.85937 2.5 2.5 5.85937 2.5 10C2.5 14.1406 5.85937 17.5 10 17.5C14.1406 17.5 17.5 14.1406 17.5 10Z"
        strokeWidth="1.25274"
        strokeMiterlimit="10"
        stroke="currentColor"
      />
      <path
        d="M13.75 6.875L8.5 13.125L6.25 10.625"
        strokeWidth="1.25274"
        strokeLinecap="round"
        strokeLinejoin="round"
        stroke="currentColor"
      />
    </CheckCircleOutlinedIconRoot>
  );
});
