import React from 'react';
import styled from 'styled-components';

import { tabClassNames } from '../Tab';
import { TabsRef, TabsProps } from './Tabs.types';

export const TabsRoot = styled.div`
  overflow: hidden;
  min-height: 26px;
  /* Add iOS momentum scrolling for iOS < 13.0 */
  -webkit-overflow-scrolling: touch;
  display: flex;
`;

export const TabsScroller = styled.div`
  position: relative;
  display: inline-block;
  flex: 1 1 auto;
  white-space: nowrap;
  overflow-x: auto;
  overflow-y: hidden;
`;

export const TabsContainer = styled.div`
  display: flex;
  justify-content: center;

  .${tabClassNames.root} {
    margin-right: 22px;

    &:last-child {
      margin-right: 0;
    }
  }
`;

export const tabsClassNames = {
  root: TabsRoot.toString().slice(1),
  scroller: TabsScroller.toString().slice(1),
  container: TabsContainer.toString().slice(1),
};

export const Tabs = React.forwardRef<TabsRef, TabsProps>(function Tabs(
  {
    className,
    style,
    'aria-label': ariaLabel,
    'aria-labelledby': ariaLabelledBy,
    value,
    onChange,
    'children': childrenProp,
    ...otherProps
  },
  ref,
) {
  // To each `Tab` child, provide index as `value` if `value` has not been
  // provided and the `onChange` event handler.
  const children = React.Children.map(childrenProp, (child, index) => {
    if (!React.isValidElement(child)) return null;

    const valueProp = child.props.value;
    const childValue = valueProp === undefined ? index : valueProp;
    const selected = childValue === value;

    return React.cloneElement(child, {
      selected,
      onChange,
      value: childValue,
    });
  });

  return (
    <TabsRoot
      ref={ref}
      className={className}
      style={style}
      data-testid="Tabs"
      {...otherProps}
    >
      <TabsScroller>
        <TabsContainer
          aria-label={ariaLabel}
          aria-labelledby={ariaLabelledBy}
          role="tablist"
        >
          {children}
        </TabsContainer>
      </TabsScroller>
    </TabsRoot>
  );
});
