/* stylelint-disable no-duplicate-selectors, no-descending-specificity */
import React from 'react';
import styled from 'styled-components';
import clsx from 'clsx';

import { iconBaseClassNames } from '../../icons/IconBase';
import { ChevronRightIcon } from '../../icons/ChevronRightIcon';
import { ChevronLeftIcon } from '../../icons/ChevronLeftIcon';
import { ChevronUpIcon } from '../../icons/ChevronUpIcon';
import { ChevronDownIcon } from '../../icons/ChevronDownIcon';
import { ButtonBase, buttonBaseClassNames } from '../ButtonBase';
import { MoveButtonRef, MoveButtonProps } from './MoveButton.types';

const variantOutlinedClassName = 'MoveButton-variantOutlined';
const variantDiscreteClassName = 'MoveButton-variantDiscrete';
const variantFloatingClassName = 'MoveButton-variantFloating';
const variantDarkClassName = 'MoveButton-variantDark';

export const MoveButtonRoot = styled.div`
  position: relative;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  vertical-align: middle;

  &.${variantOutlinedClassName}, &.${variantDiscreteClassName} {
    padding: ${({ theme }) => theme.fns.getSpacing(7)};
  }

  &.${variantDarkClassName} {
    padding: ${({ theme }) => theme.fns.getSpacing(4)};
  }

  &.${variantFloatingClassName}:not(.${buttonBaseClassNames.disabled}) {
    padding: ${({ theme }) => theme.fns.getSpacing(3)};
  }
`;

export const MoveButtonButton = styled(ButtonBase)`
  margin: 0;
  padding: 0;
  border: 0;
  min-width: auto;

  ${({ theme }) => theme.fns.getShapeStyles('round')};

  .${variantOutlinedClassName} &,
  .${variantDiscreteClassName} & {
    width: 30px;
    height: 30px;

    .${iconBaseClassNames.root} {
      font-size: 14px;
    }
  }

  .${variantDarkClassName} & {
    width: 48px;
    height: 48px;

    .${iconBaseClassNames.root} {
      font-size: 30px;
    }
  }

  .${variantOutlinedClassName}:not(.${buttonBaseClassNames.disabled}) &,
  .${variantDiscreteClassName}:not(.${buttonBaseClassNames.disabled}) &,
  .${variantFloatingClassName}:not(.${buttonBaseClassNames.disabled}) & {
    color: var(--common-black);

    background: ${({ theme }) => theme.fns.getColor('common.white', 0.25)};
  }

  .${variantOutlinedClassName}:not(.${buttonBaseClassNames.disabled}) &,
  .${variantFloatingClassName}:not(.${buttonBaseClassNames.disabled}) & {
    border: ${({ theme }) => theme.fns.getBorder('secondary')};
  }

  .${variantFloatingClassName}:not(.${buttonBaseClassNames.disabled}) & {
    width: 42px;
    height: 42px;

    box-shadow: ${({ theme }) => theme.shadows[2]};

    .${iconBaseClassNames.root} {
      font-size: 20px;
    }
  }

  .${variantDarkClassName}:not(.${buttonBaseClassNames.disabled}) & {
    color: var(--common-white);
    background: transparent;

    &:hover {
      background: ${({ theme }) => theme.fns.getColor('common.white', 0.5)};
    }
  }

  .${variantOutlinedClassName}:not(.${buttonBaseClassNames.disabled}) &,
  .${variantDiscreteClassName}:not(.${buttonBaseClassNames.disabled}) &,
  .${variantFloatingClassName}:not(.${buttonBaseClassNames.disabled}) & {
    color: var(--common-black);

    background: ${({ theme }) => theme.fns.getColor('common.white', 0.25)};

    &:hover {
      background: var(--common-white);
    }
  }
`;

export const moveButtonClassNames = {
  root: MoveButtonRoot.toString().slice(1),
  variantOutlined: variantOutlinedClassName,
  variantDiscrete: variantDiscreteClassName,
  variantFloating: variantFloatingClassName,
  button: MoveButtonButton.toString().slice(1),
};

export const moveButtonMapVariantToClassName = {
  outlined: variantOutlinedClassName,
  discrete: variantDiscreteClassName,
  floating: variantFloatingClassName,
  dark: variantDarkClassName,
};

export const moveButtonMapDirectionToIcon = {
  left: ChevronLeftIcon,
  right: ChevronRightIcon,
  up: ChevronUpIcon,
  down: ChevronDownIcon,
};

export const MoveButton = React.forwardRef<MoveButtonRef, MoveButtonProps>(
  function MoveButton(
    {
      className,
      style,
      disabled = false,
      variant = 'outlined',
      direction,
      onClick,
    },
    ref,
  ) {
    const IconComponent = moveButtonMapDirectionToIcon[direction];

    return (
      <MoveButtonRoot
        ref={ref}
        data-testid="MoveButton"
        className={clsx(moveButtonMapVariantToClassName[variant], className)}
        style={style}
        onClick={onClick}
      >
        <MoveButtonButton
          data-testid="MoveButtonButton"
          fullWidth={false}
          disabled={disabled}
        >
          <IconComponent />
        </MoveButtonButton>
      </MoveButtonRoot>
    );
  },
);
