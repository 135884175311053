import React from 'react';
import styled from 'styled-components';

import { IconBase } from '../IconBase';
import {
  ChevronLeftIconRef,
  ChevronLeftIconProps,
} from './ChevronLeftIcon.types';

export const ChevronLeftIconRoot = styled(IconBase)``;

export const chevronLeftIconClassNames = {
  root: ChevronLeftIconRoot.toString().slice(1),
};

export const ChevronLeftIcon = React.forwardRef<
  ChevronLeftIconRef,
  ChevronLeftIconProps
>(function ChevronLeftIcon(
  { className, style, size, color, ...otherProps },
  ref,
) {
  return (
    <ChevronLeftIconRoot
      data-testid="ChevronLeftIcon"
      ref={ref}
      className={className}
      style={style}
      size={size}
      color={color}
      {...otherProps}
    >
      <path
        d="M5.34331 18.6475C5.79949 19.1175 6.53498 19.1175 6.99116 18.6475L14.7277 10.6763C15.0908 10.3022 15.0908 9.69784 14.7277 9.32374L6.99116 1.35252C6.53498 0.882493 5.7995 0.882493 5.34331 1.35252C4.88713 1.82254 4.88713 2.58033 5.34331 3.05036L12.0837 10.0048L5.334 16.9592C4.88713 17.4197 4.88712 18.187 5.34331 18.6475Z"
        fill="currentColor"
        transform="rotate(180 10 10)"
      />
    </ChevronLeftIconRoot>
  );
});
