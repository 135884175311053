import React from 'react';
import styled from 'styled-components';

import { IconBase } from '../IconBase';
import { EditIconRef, EditIconProps } from './EditIcon.types';

export const EditIconRoot = styled(IconBase)``;

export const editIconClassNames = {
  root: EditIconRoot.toString().slice(1),
};

export const EditIcon = React.forwardRef<EditIconRef, EditIconProps>(
  function EditIcon({ className, style, size, color, ...otherProps }, ref) {
    return (
      <EditIconRoot
        data-testid="EditIcon"
        ref={ref}
        className={className}
        style={style}
        size={size}
        color={color}
        {...otherProps}
      >
        <path
          d="M7.77782 9.55556L6.88892 13.1112L10.4445 12.2223M7.77782 9.55556L14.8891 2.44432M7.77782 9.55556L10.4445 12.2223M14.8891 2.44432L17.5558 5.11103M14.8891 2.44432L15.3537 1.97973C16.0789 1.25445 17.2548 1.25445 17.9801 1.97973L18.0204 2.02C18.7456 2.74527 18.7456 3.92117 18.0204 4.64645L17.5558 5.11103M17.5558 5.11103L10.4445 12.2223"
          strokeWidth="0.928589"
          strokeLinecap="round"
          strokeLinejoin="round"
          stroke="currentColor"
        />
        <path
          d="M7.77764 2.88867H3.85693C2.83124 2.88867 1.99976 3.72016 1.99976 4.74585V16.1429C1.99976 17.1686 2.83124 18.0001 3.85693 18.0001H16.1429C17.1686 18.0001 18.0001 17.1686 18.0001 16.1429V12.2222"
          strokeWidth="0.928589"
          strokeLinecap="round"
          strokeLinejoin="round"
          stroke="currentColor"
        />
      </EditIconRoot>
    );
  },
);
