import React from 'react';
import styled from 'styled-components';

import { IconBase } from '../IconBase';
import {
  RadioUncheckedIconRef,
  RadioUncheckedIconProps,
} from './RadioUncheckedIcon.types';

export const RadioUncheckedIconRoot = styled(IconBase)``;

export const radioUncheckedIconClassNames = {
  root: RadioUncheckedIconRoot.toString().slice(1),
};

export const RadioUncheckedIcon = React.forwardRef<
  RadioUncheckedIconRef,
  RadioUncheckedIconProps
>(function RadioUncheckedIcon(
  { className, style, size, color, ...otherProps },
  ref,
) {
  return (
    <RadioUncheckedIconRoot
      data-testid="RadioUncheckedIcon"
      ref={ref}
      className={className}
      style={style}
      size={size}
      color={color}
      {...otherProps}
    >
      <rect
        x="1.5"
        y="1.5"
        width="17"
        height="17"
        rx="8.5"
        stroke="currentColor"
      />
    </RadioUncheckedIconRoot>
  );
});
