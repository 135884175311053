import * as React from 'react';
import styled from 'styled-components';

import { Typography } from '../Typography';
import { LinkRef, LinkProps } from './Link.types';

export const LinkRoot = styled(Typography)`
  text-decoration: underline;
  text-underline-position: under;
  color: var(--common-black);
`;

export const linkClassNames = {
  root: LinkRoot.toString().slice(1),
};

export const Link = React.forwardRef<LinkRef, LinkProps>(function Link(
  {
    className,
    style,
    to,
    hrefLang,
    rel,
    target,
    onClick,
    children,
    component = 'a',
    ...otherProps
  },
  ref,
) {
  const conditionalProps = component === 'a' ? { href: to } : { to };

  return (
    <LinkRoot
      data-testid="Link"
      ref={ref}
      className={className}
      style={style}
      as={component}
      hrefLang={hrefLang}
      rel={rel}
      target={target}
      onClick={onClick}
      {...conditionalProps}
      {...otherProps}
    >
      {children}
    </LinkRoot>
  );
});
