import React from 'react';

import { Base } from '../Base';
import type { BaseProps } from '../Base';

export const PiggyBankIcon = (props: BaseProps) => {
  return (
    <Base data-testid={props['data-testid'] ?? 'PiggyBankIcon'} {...props}>
      <svg
        width="20"
        height="20"
        viewBox="0 0 20 20"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M14 8.76922C14.2064 8.76922 14.3862 8.69263 14.5394 8.53943C14.6926 8.38623 14.7692 8.20642 14.7692 8C14.7692 7.79358 14.6926 7.61378 14.5394 7.46058C14.3862 7.30737 14.2064 7.23077 14 7.23077C13.7936 7.23077 13.6138 7.30737 13.4606 7.46058C13.3074 7.61378 13.2308 7.79358 13.2308 8C13.2308 8.20642 13.3074 8.38623 13.4606 8.53943C13.6138 8.69263 13.7936 8.76922 14 8.76922ZM6 6.26923H11V5.5H6V6.26923ZM3.25 18C2.76025 16.2538 2.26281 14.5215 1.75768 12.8029C1.25256 11.0843 1 9.31667 1 7.5C1 6.24872 1.43717 5.18589 2.31152 4.31153C3.18589 3.43718 4.24872 3 5.5 3H11C11.432 2.43077 11.9426 1.95513 12.5317 1.57308C13.1208 1.19103 13.7769 1 14.5 1C14.6346 1 14.7516 1.04967 14.851 1.14902C14.9503 1.24839 15 1.36538 15 1.5C15 1.5359 14.9939 1.5718 14.9817 1.6077C14.9696 1.6436 14.9596 1.67757 14.9519 1.70962C14.834 2.00834 14.7394 2.31442 14.6683 2.62787C14.5971 2.94134 14.5321 3.25577 14.4731 3.57115L17.4019 6.5H19V11.7442L16.3673 12.6115L14.75 18H11V16H7V18H3.25ZM3.83652 17.2308H6.23077V15.2308H11.7692V17.2308H14.1827L15.7385 12.0385L18.2308 11.1827V7.26923H17.0846L13.7308 3.91537C13.7346 3.53717 13.7696 3.17467 13.8356 2.82787C13.9016 2.48109 13.991 2.13975 14.1039 1.80385C13.5603 1.94487 13.0628 2.18429 12.6115 2.52213C12.1603 2.85994 11.7974 3.27564 11.5231 3.76923H5.5C4.45933 3.76923 3.57737 4.13086 2.85412 4.85413C2.13086 5.57738 1.76922 6.45933 1.76922 7.5C1.76922 9.17522 2.00128 10.812 2.46538 12.4102C2.92948 14.0085 3.38652 15.6154 3.83652 17.2308Z"
          fill="currentColor"
        />
      </svg>
    </Base>
  );
};
