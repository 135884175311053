import {
  OpeningStrategy,
  ModalManagerInterface,
  ModalInstance,
} from './Modal.types';

export const onlyOneOpeningStrategy: OpeningStrategy = async (modals, id) => {
  const promises = Object.values(modals).map((modal) => {
    return new Promise<void>((resolve) => {
      if (modal.id !== id && modal.isOpen && modal.onRequestClose) {
        modal.onRequestClose();
        setTimeout(resolve, modal.closeTimeoutMS);
      } else {
        resolve();
      }
    });
  });

  return Promise.all(promises).then(() => undefined);
};

export class ModalManager implements ModalManagerInterface {
  public count = 0;
  public openingStrategy?: OpeningStrategy;
  public modals: Record<string, ModalInstance> = {};

  constructor(openingStrategy: OpeningStrategy) {
    this.openingStrategy = openingStrategy;
  }

  generateId() {
    return `${this.count++}`;
  }

  add(instance: ModalInstance): void {
    this.modals[instance.id] = instance;
  }

  remove(id: string): void {
    delete this.modals[id];
  }

  async open(id: string): Promise<void> {
    if (this.openingStrategy) {
      return this.openingStrategy(this.modals, id);
    }
  }
}
