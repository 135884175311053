import React from 'react';
import { useFormikContext } from 'formik';

import { FormGroupRef, ControlledFormGroupProps } from './FormGroup.types';
import { FormGroup } from './FormGroup.component';

export const ControlledFormGroup = React.forwardRef<
  FormGroupRef,
  ControlledFormGroupProps
>(function ControlledFormGroup(
  { className, style, name, row = false, children, ...otherProps },
  ref,
) {
  const { getFieldMeta } = useFormikContext();
  const meta = getFieldMeta(name);

  // Get text helper and error state from Formik state
  const helperText = meta.touched ? meta.error : undefined;
  const error = Boolean(helperText);

  return (
    <FormGroup
      ref={ref}
      className={className}
      style={style}
      row={row}
      helperText={helperText}
      error={error}
      {...otherProps}
    >
      {children}
    </FormGroup>
  );
});
