import React from 'react';
import styled from 'styled-components';

import { IconBase } from '../IconBase';
import {
  LoadingMoreIconRef,
  LoadingMoreIconProps,
} from './LoadingMoreIcon.types';

export const LoadingMoreIconRoot = styled(IconBase)``;

export const loadingMoreIconClassNames = {
  root: LoadingMoreIconRoot.toString().slice(1),
};

export const LoadingMoreIcon = React.forwardRef<
  LoadingMoreIconRef,
  LoadingMoreIconProps
>(function LoadingMoreIcon(
  { className, style, size, color, ...otherProps },
  ref,
) {
  return (
    <LoadingMoreIconRoot
      data-testid="LoadingMoreIcon"
      ref={ref}
      className={className}
      style={style}
      size={size}
      color={color}
      {...otherProps}
    >
      <circle r="1" cx="5" cy="10" fill="currentColor">
        <animate
          attributeName="cy"
          values="10;7;10;10;10"
          dur="2s"
          repeatCount="indefinite"
        />
      </circle>
      <circle r="1" cx="10" cy="10" fill="currentColor">
        <animate
          attributeName="cy"
          values="10;10;7;10;10"
          dur="2s"
          repeatCount="indefinite"
        />
      </circle>
      <circle r="1" cx="15" cy="10" fill="currentColor">
        <animate
          attributeName="cy"
          values="10;10;10;7;10"
          dur="2s"
          repeatCount="indefinite"
        />
      </circle>
    </LoadingMoreIconRoot>
  );
});
