import React from 'react';
import { useField } from 'formik';

import { Checkbox } from './Checkbox.component';
import { CheckboxRef, ControlledCheckboxProps } from './Checkbox.types';

export const ControlledCheckbox = React.forwardRef<
  CheckboxRef,
  ControlledCheckboxProps
>(function ControlledCheckbox(
  {
    id,
    name: nameProp,
    className,
    style,
    defaultChecked,
    checked: checkedProp,
    value: valueProp,
    disabled = false,
    required = false,
    inputRef,
    onChange: onChangeProp,
  },
  ref,
) {
  const [{ name, onChange, value, checked }] = useField({
    type: 'checkbox',
    id,
    name: nameProp,
    className,
    style,
    defaultChecked,
    checked: checkedProp,
    value: valueProp,
    disabled,
    required,
    ref: inputRef,
    onChange: onChangeProp,
  });

  return (
    <Checkbox
      ref={ref}
      id={id}
      className={className}
      style={style}
      name={name}
      value={value}
      checked={checked}
      onChange={onChange}
    />
  );
});
