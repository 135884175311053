import React from 'react';
import styled from 'styled-components';

import { IconBase } from '../IconBase';
import { VideoCamIconRef, VideoCamIconProps } from './VideoCamIcon.types';

export const VideoCamIconRoot = styled(IconBase)``;

export const videoCamIconClassNames = {
  root: VideoCamIconRoot.toString().slice(1),
};

export const VideoCamIcon = React.forwardRef<
  VideoCamIconRef,
  VideoCamIconProps
>(function VideoCamIcon({ className, style, size, color, ...otherProps }, ref) {
  return (
    <VideoCamIconRoot
      data-testid="VideoCamIcon"
      ref={ref}
      className={className}
      style={style}
      size={size}
      color={color}
      {...otherProps}
    >
      <path
        d="M18.125 15.0152C17.95 15.015 17.777 14.9782 17.6172 14.907C17.58 14.8905 17.5445 14.8703 17.5113 14.8468L14.2805 12.5726C14.1166 12.4572 13.9829 12.3042 13.8906 12.1264C13.7983 11.9485 13.75 11.7511 13.75 11.5507V8.44916C13.75 8.24878 13.7983 8.05135 13.8906 7.87351C13.9829 7.69566 14.1166 7.54262 14.2805 7.42728L17.5113 5.15306C17.5445 5.12956 17.58 5.10941 17.6172 5.09291C17.8075 5.00829 18.0159 4.97258 18.2236 4.989C18.4312 5.00542 18.6314 5.07347 18.8061 5.18695C18.9807 5.30043 19.1242 5.45575 19.2236 5.6388C19.323 5.82184 19.375 6.02682 19.375 6.23509V13.7648C19.375 14.0963 19.2433 14.4142 19.0089 14.6487C18.7745 14.8831 18.4565 15.0148 18.125 15.0148V15.0152Z"
        fill="currentColor"
      />
      <path
        d="M10.4688 15.625H3.28125C2.57699 15.6243 1.90178 15.3442 1.4038 14.8462C0.90581 14.3482 0.625724 13.673 0.625 12.9688V7.03125C0.625724 6.32699 0.90581 5.65178 1.4038 5.1538C1.90178 4.65581 2.57699 4.37572 3.28125 4.375H10.4875C11.1868 4.37583 11.8571 4.65397 12.3516 5.14842C12.846 5.64287 13.1242 6.31325 13.125 7.0125V12.9688C13.1243 13.673 12.8442 14.3482 12.3462 14.8462C11.8482 15.3442 11.173 15.6243 10.4688 15.625Z"
        fill="currentColor"
      />
    </VideoCamIconRoot>
  );
});
