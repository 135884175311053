import React from 'react';

import { Base } from '../Base';
import type { BaseProps } from '../Base';

export const MinusCircleIcon = (props: BaseProps) => {
  return (
    <Base data-testid={props['data-testid'] ?? 'MinusCircleIcon'} {...props}>
      <svg
        width="36"
        height="36"
        viewBox="0 0 36 36"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <circle cx="18" cy="18" r="15.5" fill="white" stroke="black" />
        <path
          d="M10.6484 18H25.3484"
          stroke="black"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    </Base>
  );
};
