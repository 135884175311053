import React from 'react';
import styled from 'styled-components';

import { IconBase } from '../IconBase';
import { DashboardIconRef, DashboardIconProps } from './DashboardIcon.types';

export const DashboardIconRoot = styled(IconBase)``;

export const DashboardIconClassNames = {
  root: DashboardIconRoot.toString().slice(1),
};

export const DashboardIcon = React.forwardRef<
  DashboardIconRef,
  DashboardIconProps
>(function DashboardIcon(
  { className, style, size, color, ...otherProps },
  ref,
) {
  return (
    <DashboardIconRoot
      data-testid="DashboardIcon"
      ref={ref}
      className={className}
      style={style}
      size={size}
      color={color}
      {...otherProps}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M2.5 3C1.94772 3 1.5 3.44772 1.5 4V16C1.5 16.5523 1.94772 17 2.5 17H17.5C18.0523 17 18.5 16.5523 18.5 16V4C18.5 3.44772 18.0523 3 17.5 3H2.5ZM3.5 4.16667C2.94772 4.16667 2.5 4.61438 2.5 5.16667V15.8333H11.5V5.16667C11.5 4.61438 11.0523 4.16667 10.5 4.16667H3.5ZM12.5 5.16667C12.5 4.61438 12.9477 4.16667 13.5 4.16667H16.5C17.0523 4.16667 17.5 4.61438 17.5 5.16667V7.83333C17.5 8.38562 17.0523 8.83333 16.5 8.83333H13.5C12.9477 8.83333 12.5 8.38562 12.5 7.83333V5.16667ZM13.5 10C12.9477 10 12.5 10.4477 12.5 11V15.8333H17.5V11C17.5 10.4477 17.0523 10 16.5 10H13.5Z"
        fill="currentColor"
      />
      <path
        d="M6.72222 14V6H7.27778V14H6.72222ZM8.59214 8.40001C8.55149 8.08334 8.38663 7.8375 8.09756 7.6625C7.80849 7.4875 7.45393 7.4 7.03388 7.4C6.72674 7.4 6.458 7.44584 6.22764 7.5375C5.99955 7.62917 5.82114 7.75521 5.69241 7.91562C5.56595 8.07604 5.50271 8.25833 5.50271 8.4625C5.50271 8.63334 5.54675 8.78021 5.63483 8.90312C5.72516 9.02396 5.84034 9.125 5.98035 9.20625C6.12037 9.28542 6.26716 9.35104 6.42073 9.40313C6.5743 9.45312 6.71545 9.49375 6.84417 9.52501L7.54878 9.7C7.72945 9.74375 7.93044 9.80417 8.15176 9.88125C8.37534 9.95834 8.58875 10.0635 8.79201 10.1969C8.99752 10.3281 9.1669 10.4969 9.30014 10.7031C9.43338 10.9094 9.5 11.1625 9.5 11.4625C9.5 11.8083 9.40176 12.1208 9.20528 12.4C9.01106 12.6792 8.72651 12.901 8.35163 13.0656C7.979 13.2302 7.5262 13.3125 6.99322 13.3125C6.49639 13.3125 6.06617 13.2386 5.70257 13.0906C5.34124 12.9427 5.05668 12.7365 4.84892 12.4719C4.64341 12.2073 4.5271 11.9 4.5 11.55H5.36721C5.3898 11.7917 5.47787 11.9917 5.63143 12.15C5.78727 12.3063 5.98374 12.4229 6.22087 12.5C6.46026 12.575 6.71771 12.6125 6.99322 12.6125C7.31391 12.6125 7.60185 12.5646 7.85705 12.4688C8.11224 12.3708 8.31436 12.2354 8.46341 12.0625C8.61246 11.8875 8.68699 11.6833 8.68699 11.45C8.68699 11.2375 8.62263 11.0646 8.49391 10.9313C8.36517 10.7979 8.1958 10.6896 7.98577 10.6063C7.77575 10.5229 7.54878 10.45 7.30488 10.3875L6.45122 10.1625C5.90922 10.0188 5.48012 9.81354 5.16396 9.54688C4.84779 9.28021 4.6897 8.93125 4.6897 8.5C4.6897 8.14167 4.79472 7.82917 5.00474 7.5625C5.21703 7.29375 5.50158 7.08542 5.8584 6.9375C6.21748 6.7875 6.61834 6.7125 7.06098 6.7125C7.50813 6.7125 7.9056 6.78646 8.25339 6.93438C8.60118 7.08021 8.87669 7.28021 9.07995 7.53438C9.28545 7.78854 9.39386 8.07708 9.40515 8.40001H8.59214Z"
        fill="currentColor"
      />
    </DashboardIconRoot>
  );
});
