import React from 'react';
import styled from 'styled-components';
import { FormikValues, Formik, Form as FormikForm } from 'formik';

import { FormProps } from './Form.types';

export const FormRoot = styled(FormikForm)``;

export const formClassNames = {
  root: FormRoot.toString().slice(1),
};

export function Form<Values extends FormikValues = FormikValues>({
  className,
  style,
  children,
  formRef,
  ...otherProps
}: FormProps<Values>) {
  return (
    <Formik {...otherProps}>
      <FormRoot
        data-testid="Form"
        ref={formRef}
        className={className}
        style={style}
      >
        {children}
      </FormRoot>
    </Formik>
  );
}
