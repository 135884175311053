import React from 'react';
import styled from 'styled-components';

import { IconBase } from '../IconBase';
import { AndroidIconRef, AndroidIconProps } from './AndroidIcon.types';

export const AndroidIconRoot = styled(IconBase)``;

export const AndroidIconClassNames = {
  root: AndroidIconRoot.toString().slice(1),
};

export const AndroidIcon = React.forwardRef<AndroidIconRef, AndroidIconProps>(
  function AndroidIcon({ className, style, size }, ref) {
    return (
      <AndroidIconRoot
        data-testid="AndroidIcon"
        ref={ref}
        className={className}
        style={style}
        size={size}
      >
        <g clipPath="url(#clip0_3251_19084)">
          <path
            d="M14.8793 7.77353L16.5383 4.8997C16.5828 4.82255 16.5949 4.73095 16.5721 4.64488C16.5492 4.55881 16.4933 4.48527 16.4164 4.44032C16.3784 4.41803 16.3364 4.4035 16.2928 4.39757C16.2492 4.39163 16.2048 4.39441 16.1622 4.40575C16.1197 4.41709 16.0798 4.43675 16.0449 4.46361C16.01 4.49046 15.9808 4.52397 15.959 4.5622L14.2793 7.47313C12.9969 6.8872 11.5535 6.56103 10.0012 6.56103C8.44883 6.56103 7.00547 6.88759 5.72305 7.47313L4.04336 4.5622C3.9986 4.48512 3.92506 4.42898 3.83891 4.40612C3.75276 4.38327 3.66106 4.39557 3.58398 4.44032C3.50691 4.48508 3.45076 4.55862 3.42791 4.64477C3.40505 4.73092 3.41735 4.82262 3.46211 4.8997L5.11719 7.77353C2.25781 9.32196 0.319922 12.2177 0 15.6075H20C19.6801 12.2177 17.7422 9.32196 14.8793 7.77353ZM5.4082 12.7989C5.24241 12.7989 5.08033 12.7498 4.94248 12.6576C4.80462 12.5655 4.69718 12.4346 4.63373 12.2814C4.57028 12.1283 4.55368 11.9597 4.58603 11.7971C4.61837 11.6345 4.69821 11.4851 4.81545 11.3679C4.93268 11.2506 5.08205 11.1708 5.24466 11.1385C5.40727 11.1061 5.57582 11.1227 5.729 11.1862C5.88218 11.2496 6.0131 11.3571 6.10521 11.4949C6.19732 11.6328 6.24648 11.7948 6.24648 11.9606C6.24638 12.1829 6.15803 12.3961 6.00084 12.5533C5.84366 12.7105 5.6305 12.7988 5.4082 12.7989ZM14.5879 12.7989C14.4222 12.7982 14.2605 12.7485 14.1231 12.6559C13.9857 12.5634 13.8788 12.4322 13.8159 12.279C13.753 12.1258 13.7369 11.9573 13.7697 11.7949C13.8024 11.6326 13.8825 11.4835 13.9999 11.3666C14.1173 11.2498 14.2666 11.1703 14.4292 11.1382C14.5917 11.1062 14.76 11.123 14.913 11.1865C15.066 11.2501 15.1967 11.3575 15.2887 11.4953C15.3806 11.6331 15.4297 11.795 15.4297 11.9606C15.4296 12.0709 15.4079 12.18 15.3656 12.2818C15.3233 12.3836 15.2614 12.4761 15.1834 12.554C15.1053 12.6318 15.0127 12.6935 14.9108 12.7356C14.8089 12.7776 14.6997 12.7991 14.5895 12.7989H14.5879Z"
            fill="#303030"
          />
        </g>
        <defs>
          <clipPath id="clip0_3251_19084">
            <rect width="20" height="20" fill="white" />
          </clipPath>
        </defs>
      </AndroidIconRoot>
    );
  },
);
