import React from 'react';
import styled from 'styled-components';

import { useLoaded } from '../../../hooks/useLoaded';
import { NoPhotographyOutlinedIcon } from '../../icons/NoPhotographyOutlinedIcon';
import { StarIcon } from '../../icons/StarIcon';
import { DeleteIcon } from '../../icons/DeleteIcon';
import { RefreshIcon } from '../../icons/RefreshIcon';
import { Stack } from '../Stack';
import { EditImageListButton } from '../EditImageListButton';
import {
  EditImageListItemRef,
  EditImageListItemProps,
} from './EditImageListItem.types';

export const EditImageListItemRoot = styled.li`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-shrink: 0;
  background-color: var(--grey-5);
  width: 94px;
  height: 114px;

  ${({ theme }) => theme.fns.getShapeStyles('super-soft')}
`;

export const EditImageListItemImage = styled.img`
  margin: 0;
  width: 100%;
  height: 100%;
  text-align: center;
  overflow: hidden;
  background: var(--grey-7);
  ${({ theme }) => theme.fns.getShapeStyles('super-soft')}

  /* Handle non-square image. The property isn't supported by IE11. */
  object-fit: cover;
  /* Hide alt text. */
  color: transparent;
  /* Hide the image broken icon, only works on Chrome. */
  text-indent: 10000;
`;

export const EditImageListItemNoImageContainer = styled(Stack)`
  width: 100%;
  height: 100%;
  background-color: var(--grey-5);

  ${({ theme }) => theme.fns.getShapeStyles('super-soft')}
`;

export const EditImageListItemStarButton = styled(EditImageListButton)`
  position: absolute;
  left: 6px;
  top: 6px;
`;

export const EditImageListItemDeleteButton = styled(EditImageListButton)`
  position: absolute;
  right: 6px;
  bottom: 6px;
`;

export const editImageListItemClassNames = {
  root: EditImageListItemRoot.toString().slice(1),
  image: EditImageListItemImage.toString().slice(1),
  noImageContainer: EditImageListItemNoImageContainer.toString().slice(1),
  starButton: EditImageListItemStarButton.toString().slice(1),
  deleteButton: EditImageListItemDeleteButton.toString().slice(1),
};

export const EditImageListItem = React.forwardRef<
  EditImageListItemRef,
  EditImageListItemProps
>(function EditImageListItem(
  {
    className,
    style,
    alt,
    src,
    srcSet,
    innerProps = {},
    starred = false,
    deleted = false,
    onStar,
    onDelete,
    ...otherProps
  },
  ref,
) {
  // Use a hook instead of onError on the img element to support server-side rendering.
  const loaded = useLoaded({ ...innerProps.image, src, srcSet });
  const hasImage = src || srcSet;
  const hasImageNotFailing = hasImage && loaded !== 'error';
  const hasOnStarHandler = Boolean(onStar);
  const hasOnDeleteHandler = Boolean(onDelete);

  return (
    <EditImageListItemRoot
      ref={ref}
      className={className}
      style={style}
      data-testid="EditImageListItem"
      {...otherProps}
    >
      {deleted ? null : hasImageNotFailing ? (
        <EditImageListItemImage
          alt={alt}
          src={src}
          srcSet={srcSet}
          {...innerProps.image}
        />
      ) : (
        <EditImageListItemNoImageContainer
          direction="column"
          justifyContent="center"
          alignItems="center"
        >
          <NoPhotographyOutlinedIcon color="grey.1" size="larger" />
        </EditImageListItemNoImageContainer>
      )}
      {!deleted && hasOnStarHandler && (
        <EditImageListItemStarButton
          className="EditImageListItem-starButton"
          onClick={onStar}
        >
          {starred && <StarIcon size="small" />}
        </EditImageListItemStarButton>
      )}
      {hasOnDeleteHandler && (
        <EditImageListItemDeleteButton
          className="EditImageListItem-deleteButton"
          onClick={onDelete}
        >
          {deleted ? <RefreshIcon size="small" /> : <DeleteIcon size="small" />}
        </EditImageListItemDeleteButton>
      )}
    </EditImageListItemRoot>
  );
});
