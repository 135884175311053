import React from 'react';
import styled from 'styled-components';

import { IconBase } from '../IconBase';
import {
  MoreTwoToneIconRef,
  MoreTwoToneIconProps,
} from './MoreTwoToneIcon.types';

export const MoreTwoToneIconRoot = styled(IconBase)``;

export const moreTwoToneIconClassNames = {
  root: MoreTwoToneIconRoot.toString().slice(1),
};

export const MoreTwoToneIcon = React.forwardRef<
  MoreTwoToneIconRef,
  MoreTwoToneIconProps
>(function MoreTwoToneIcon(
  { className, style, size, color, secondaryColor, ...otherProps },
  ref,
) {
  return (
    <MoreTwoToneIconRoot
      data-testid="MoreTwoToneIcon"
      ref={ref}
      className={className}
      style={style}
      size={size}
      color={color}
      secondaryColor={secondaryColor}
      {...otherProps}
    >
      <rect
        x="0.5"
        y="0.5"
        width="19"
        height="19"
        rx="9.5"
        fill="var(--secondary-color)"
      />
      <circle r="1" cx="5" cy="10" fill="currentColor" />
      <circle r="1" cx="10" cy="10" fill="currentColor" />
      <circle r="1" cx="15" cy="10" fill="currentColor" />
    </MoreTwoToneIconRoot>
  );
});
