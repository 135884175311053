import React, {
  forwardRef,
  ForwardRefExoticComponent,
  RefAttributes,
} from 'react';
import styled, { css } from 'styled-components';
import clsx from 'clsx';

import { Paper, PaperProps, paperClassNames } from '../Paper';
import { Typography } from '../Typography';
import { InfoTwoToneIcon } from '../../icons/InfoTwoToneIcon';
import { CheckCircleOutlinedIcon } from '../../icons/CheckCircleOutlinedIcon';
import { AlertRef, AlertProps } from './Alert.types';

const mapSeverityToClassName: Record<
  NonNullable<AlertProps['severity']>,
  string
> = {
  error: 'Alert-severityError',
  warning: 'Alert-severityWarning',
  info: 'Alert-severityInfo',
  success: 'Alert-severifySuccess',
};

const mapSeverityToIcon = {
  // TODO Implement warning and error icons and potentially improve success icon
  error: null,
  warning: null,
  info: <InfoTwoToneIcon color="inherit" secondaryColor="common.white" />,
  success: <CheckCircleOutlinedIcon color="inherit" />,
};

export const AlertRoot = styled<
  ForwardRefExoticComponent<
    PaperProps & RefAttributes<HTMLDivElement> & { role: 'alert' }
  >
>(Paper)`
  display: flex;
  padding: 10px 20px;
  background-color: transparent;

  &.${paperClassNames.root} {
    box-shadow: none;
  }

  &.${mapSeverityToClassName.error} {
    background-color: ${({ theme }) => {
      const color = theme.fns.getColor('error.light');
      return theme.fns.lighten(0.335, color);
    }};
  }

  &.${mapSeverityToClassName.warning} {
    background-color: ${({ theme }) => {
      const color = theme.fns.getColor('warning.light');
      return theme.fns.lighten(0.45, color);
    }};
  }

  &.${mapSeverityToClassName.info} {
    background-color: ${({ theme }) => theme.fns.getColor('info.light')};
  }

  &.${mapSeverityToClassName.success} {
    background-color: ${({ theme }) => {
      const color = theme.fns.getColor('success.light');
      return theme.fns.lighten(0.45, color);
    }};
  }
`;

const fontColorStyles = css`
  .${mapSeverityToClassName.error} & {
    color: ${({ theme }) => {
      const color = theme.fns.getColor('error.dark');
      return theme.fns.darken(0.24, color);
    }};
  }

  /* stylelint-disable-next-line no-duplicate-selectors */
  .${mapSeverityToClassName.warning} & {
    color: ${({ theme }) => {
      const color = theme.fns.getColor('warning.dark');
      return theme.fns.darken(0.25, color);
    }};
  }

  /* stylelint-disable-next-line no-duplicate-selectors */
  .${mapSeverityToClassName.info} & {
    color: ${({ theme }) => theme.fns.getColor('info.dark')};
  }

  /* stylelint-disable-next-line no-duplicate-selectors */
  .${mapSeverityToClassName.success} & {
    color: ${({ theme }) => {
      const color = theme.fns.getColor('success.dark');
      return theme.fns.darken(0.1, color);
    }};
  }
`;

export const AlertIcon = styled.div`
  margin-right: 12px;
  padding: 2px 0;
  display: flex;

  ${fontColorStyles}
`;

export const AlertMessage = styled(Typography)`
  min-width: 0;
  overflow: auto;

  ${fontColorStyles}
`;

export const alertClassNames = {
  root: AlertRoot.toString().slice(1),
  severityError: mapSeverityToClassName.error,
  severityWarning: mapSeverityToClassName.warning,
  severityInfo: mapSeverityToClassName.info,
  severitySuccess: mapSeverityToClassName.success,
  icon: AlertIcon.toString().slice(1),
  message: AlertMessage.toString().slice(1),
};

export const Alert = forwardRef<AlertRef, AlertProps>(function Alert(
  { className, style, severity = 'success', children },
  ref,
) {
  return (
    <AlertRoot
      data-testid="Alert"
      ref={ref}
      className={clsx(className, mapSeverityToClassName[severity])}
      style={style}
      role="alert"
    >
      {mapSeverityToIcon[severity] && (
        <AlertIcon>{mapSeverityToIcon[severity]}</AlertIcon>
      )}
      <AlertMessage variant="primary.r16">{children}</AlertMessage>
    </AlertRoot>
  );
});
