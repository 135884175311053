import React from 'react';
import styled from 'styled-components';

import { IconBase } from '../IconBase';
import {
  NoPhotographyOutlinedIconRef,
  NoPhotographyOutlinedIconProps,
} from './NoPhotographyOutlinedIcon.types';

export const NoPhotographyOutlinedIconRoot = styled(IconBase)``;

export const noPhotographyOutlinedIconClassNames = {
  root: NoPhotographyOutlinedIconRoot.toString().slice(1),
};

export const NoPhotographyOutlinedIcon = React.forwardRef<
  NoPhotographyOutlinedIconRef,
  NoPhotographyOutlinedIconProps
>(function NoPhotographyOutlinedIcon(
  { className, style, size, color, ...otherProps },
  ref,
) {
  return (
    <NoPhotographyOutlinedIconRoot
      data-testid="NoPhotographyOutlinedIcon"
      ref={ref}
      className={className}
      style={style}
      size={size}
      color={color}
      {...otherProps}
    >
      <path
        d="M18.3307 16.5208L17.0807 15.2917V5.5625H13.4349L11.9141 3.75H8.08073L6.91406 5.125L6.03906 4.25L7.4974 2.5H12.4974L14.0182 4.3125H17.0807C17.4002 4.3125 17.6884 4.44097 17.9453 4.69792C18.2023 4.95486 18.3307 5.24306 18.3307 5.5625V16.5208ZM13.4557 11.6667L9.2474 7.45833C9.84462 7.36111 10.4314 7.38542 11.0078 7.53125C11.5842 7.67708 12.0877 7.96528 12.5182 8.39583C12.9488 8.82639 13.2405 9.32986 13.3932 9.90625C13.546 10.4826 13.5668 11.0694 13.4557 11.6667ZM4.3099 4.3125L5.5599 5.5625H2.91406V16.25H14.4557L0.539062 2.33333L1.4349 1.4375L18.2474 18.25L17.3516 19.1458L15.7057 17.5H2.91406C2.58073 17.5 2.28906 17.375 2.03906 17.125C1.78906 16.875 1.66406 16.5833 1.66406 16.25V5.5625C1.66406 5.22917 1.78906 4.9375 2.03906 4.6875C2.28906 4.4375 2.58073 4.3125 2.91406 4.3125H4.3099ZM7.53906 8.41667L8.4349 9.3125C8.19879 9.53472 8.0217 9.78125 7.90365 10.0521C7.78559 10.3229 7.72656 10.6181 7.72656 10.9375C7.72656 11.5625 7.94879 12.0972 8.39323 12.5417C8.83767 12.9861 9.3724 13.2083 9.9974 13.2083C10.303 13.2083 10.5946 13.1458 10.8724 13.0208C11.1502 12.8958 11.4002 12.7222 11.6224 12.5L12.5182 13.3958C12.1849 13.7431 11.7995 14.0069 11.362 14.1875C10.9245 14.3681 10.4696 14.4583 9.9974 14.4583C9.02517 14.4583 8.19531 14.1146 7.50781 13.4271C6.82031 12.7396 6.47656 11.9097 6.47656 10.9375C6.47656 10.4653 6.56684 10.0104 6.7474 9.57292C6.92795 9.13542 7.19184 8.75 7.53906 8.41667Z"
        fill="currentColor"
      />
    </NoPhotographyOutlinedIconRoot>
  );
});
