import React from 'react';

import { setRef } from '../../utils/setRef';
import { UseForkRefHook } from './useForkRef.types';

/**
 * This will create a new callback ref that will set {refA}
 * and {refB} and will keep them in sync so that both make
 * reference to the same instance.
 *
 * @param refA A ref callback, ref object, null or undefined.
 * @param refB A ref callback, ref object, null or undefined.
 */
export const useForkRef: UseForkRefHook = (refA, refB) => {
  return React.useMemo(() => {
    if (refA == null && refB == null) {
      return null;
    }
    return (refValue) => {
      setRef(refA, refValue);
      setRef(refB, refValue);
    };
  }, [refA, refB]);
};
