/* eslint-disable react-hooks/rules-of-hooks, react-hooks/exhaustive-deps */
import * as React from 'react';

import { UseControlledProps } from './useControlled.types';

/**
 * This React hook enables components it is used in to be either controlled or
 * uncontrolled as specified by the input props.
 * It receives both the uncontrolled and the controlled props of an
 * input component, like `defaultChecked` and `checked` in checkboxes and
 * `defaultValue` and `value` in other native input components, and returns the
 * actual value the input component is going to use and a setter for that value
 * that will only set the value if the component was initialised as
 * uncontrolled.
 *
 * Heavily based on MUI's useControlled
 * https://github.com/mui-org/material-ui/blob/2db48e8cabeef509e6b5c1aac0acee95f4b6c11c/packages/material-ui-utils/src/useControlled.js
 *
 * To know more about the differences between uncontrolled and controlled inputs
 * see
 * https://reactjs.org/docs/uncontrolled-components.html
 * https://goshakkk.name/controlled-vs-uncontrolled-inputs-react/
 */
export const useControlled = <T>({
  controlled,
  default: defaultProp,
}: UseControlledProps<T>): [
  T | undefined,
  (newValue: T | ((prevValue: T) => T)) => void,
] => {
  // When the component is first instantiated we determine if it is controlled
  // or uncontrolled.
  const { current: isControlled } = React.useRef(controlled !== undefined);

  // When uncontrolled, if we want to keep track of the value living in the DOM,
  // we have to use `setValue` inside an `onChange` handler.
  const [valueState, setValue] = React.useState(defaultProp);

  // Thus, the value returned by this hook will depend on whether it was
  // controlled at initialization time.
  const value = isControlled ? controlled : valueState;

  if (process.env.NODE_ENV !== 'production') {
    React.useEffect(() => {
      if (isControlled !== (controlled !== undefined)) {
        console.error(
          [
            'An input component is changing between controlled and uncontrolled or vice versa.',
            'Elements should not do that.',
            'Decide between using a controlled or uncontrolled lifetime of the component.',
            'The nature of the state is determined during the first render.',
            "It's considered controlled if the value is not `undefined`.",
            'More info: https://fb.me/react-controlled-components',
          ].join('\n'),
        );
      }
    }, [isControlled, controlled]);

    const { current: defaultValue } = React.useRef(defaultProp);

    React.useEffect(() => {
      if (!isControlled && defaultValue !== defaultProp) {
        console.error(
          [
            'An uncontrolled input component is changing the default state after being initialized.',
            'To suppress this warning opt to use a controlled component.',
          ].join('\n'),
        );
      }
    }, [JSON.stringify(defaultProp)]);
  }

  // This setter, also returned by this hook, can be used seamlessly and
  // independently for both controlled and uncontrolled components.
  // When called, it will keep the value in sync with the DOM if uncontrolled
  // or do nothing if controlled.
  const setValueIfUncontrolled = React.useCallback(
    (newValue) => {
      if (!isControlled) {
        setValue(newValue);
      }
    },
    [isControlled],
  );

  return [value, setValueIfUncontrolled];
};
