import React, { forwardRef } from 'react';
import styled from 'styled-components';
import clsx from 'clsx';

import { RadioUncheckedIcon } from '../../icons/RadioUncheckedIcon';
import { RadioCheckedIcon } from '../../icons/RadioCheckedIcon';
import { createChainedFunction } from '../../../utils/createChainedFunction';
import { SwitchBaseProps, SwitchBase } from '../SwitchBase';
import { useRadioGroup } from '../RadioGroup';
import { RadioRef, RadioProps } from './Radio.types';

/**
 * Checks whether two input values are equal.
 */
function areEqualValues(
  a: SwitchBaseProps['value'],
  b: SwitchBaseProps['value'],
) {
  if (typeof b === 'object' && b !== null) {
    return a === b;
  }

  // The value could be a number, the DOM will stringify it anyway.
  return String(a) === String(b);
}

const radioRootDisabledClassName = 'Radio-disabled';

export const RadioRoot = styled(SwitchBase)``;

export const radioClassNames = {
  root: RadioRoot.toString().slice(1),
  disabled: radioRootDisabledClassName,
};

const icon = <RadioUncheckedIcon />;
const checkedIcon = <RadioCheckedIcon />;

export const Radio = forwardRef<RadioRef, RadioProps>(function Radio(
  {
    checked: checkedProp,
    className,
    defaultChecked,
    disabled = false,
    id,
    inputRef,
    name: nameProp,
    onChange: onChangeProp,
    required = false,
    style,
    tabIndex,
    value,
    ...otherProps
  },
  ref,
) {
  const radioGroup = useRadioGroup();

  let checked = checkedProp;
  const onChange = createChainedFunction(onChangeProp, radioGroup?.onChange);
  let name = nameProp;

  if (radioGroup) {
    if (typeof checked === 'undefined') {
      checked = areEqualValues(radioGroup.value, value);
    }
    if (typeof name === 'undefined') {
      name = radioGroup.name;
    }
  }

  return (
    <RadioRoot
      checked={checked}
      checkedIcon={checkedIcon}
      className={clsx(disabled && radioRootDisabledClassName, className)}
      data-testid="Radio"
      defaultChecked={defaultChecked}
      disabled={disabled}
      icon={icon}
      id={id}
      inputRef={inputRef}
      name={name}
      onChange={onChange}
      ref={ref}
      required={required}
      style={style}
      tabIndex={tabIndex}
      type="radio"
      value={value}
      {...otherProps}
    />
  );
});
