import React from 'react';
import styled from 'styled-components';

import { LinearProgressRef, LinearProgressProps } from './LinearProgress.types';

/**
 * Clip value to be in the range between min and max and round it an integer.
 */
export const clipValue = (value: number, min: number, max: number): number => {
  const integerValue = Math.round(value);
  const minClipped = Math.max(min, integerValue);
  const maxClipped = Math.min(minClipped, max);
  return maxClipped;
};

export const LinearProgressRoot = styled.span`
  position: relative;
  overflow: hidden;
  display: block;
  z-index: 0;
  background-color: var(--grey-5);
  height: 8px;
  border-radius: 10px;
`;

export const LinearProgressBar = styled.span`
  width: 100%;
  position: absolute;
  left: 0;
  bottom: 0;
  top: 0;
  transition: transform 0.4s linear;
  transform-origin: left;
  background-color: var(--common-black);
`;

export const linearProgressClassNames = {
  root: LinearProgressRoot.toString().slice(1),
  bar: LinearProgressBar.toString().slice(1),
};

export const LinearProgress = React.forwardRef<
  LinearProgressRef,
  LinearProgressProps
>(({ className, style, value }, ref) => {
  // Clip number to be between 0 and 100
  const clippedValue = clipValue(value, 0, 100);
  return (
    <LinearProgressRoot
      ref={ref}
      className={className}
      data-testid="LinearProgress"
      style={style}
      role="progressbar"
      aria-valuemin={0}
      aria-valuemax={100}
      aria-valuenow={clippedValue}
    >
      <LinearProgressBar
        style={{ transform: `translateX(${clippedValue - 100}%)` }}
      />
    </LinearProgressRoot>
  );
});
