import React from 'react';
import styled, { createGlobalStyle } from 'styled-components';
import clsx from 'clsx';
import ReactModal from 'react-modal';

import { setRef } from '../../../utils/setRef';
import { ModalProps } from './Modal.types';
import { useModal } from './Modal.hook';

// Override default styles the react-modal library applies
ReactModal.defaultStyles = {
  overlay: {},
  content: {},
};

export const setAppElement = (appElement: string | HTMLElement): void => {
  ReactModal.setAppElement(appElement);
};

// Avoid scrollbar on main content while modal is open
const ModalGlobalStyle = createGlobalStyle`
  html, body {
    overflow: hidden;
  }
`;

const ModalRoot = styled(ReactModal)``;

export const modalOverlayAnchorClassNames: Record<
  NonNullable<ModalProps['anchor']>,
  string
> = {
  left: 'ModalOverlay-anchorLeft',
  right: 'ModalOverlay-anchorRight',
  top: 'ModalOverlay-anchorTop',
  bottom: 'ModalOverlay-anchorBottom',
};

export const modalOverlayModeClassNames: Record<
  NonNullable<ModalProps['mode']>,
  string
> = {
  modal: 'ModalOverlay-modeModal',
  drawer: 'ModalOverlay-modeDrawer',
};

const ModalOverlay = styled.div.attrs({
  'data-testid': 'ModalOverlay',
})`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(0, 0, 0, 0);
  transition: background-color 225ms cubic-bezier(0, 0, 0.2, 1) 0ms;
  z-index: 10000;

  &.ReactModal__Overlay--after-open {
    background-color: rgba(0, 0, 0, 0.5);
  }

  &.ReactModal__Overlay--before-close {
    background-color: rgba(0, 0, 0, 0);
  }

  &.${modalOverlayAnchorClassNames.left} {
    justify-content: flex-start;
  }

  &.${modalOverlayAnchorClassNames.right} {
    justify-content: flex-end;
  }

  &.${modalOverlayAnchorClassNames.top} {
    align-items: flex-start;
  }

  &.${modalOverlayAnchorClassNames.bottom} {
    align-items: flex-end;
  }
`;

/* stylelint-disable no-descending-specificity */
/* stylelint-disable no-duplicate-selectors */
const ModalContent = styled.div.attrs({
  'data-testid': 'ModalContent',
})`
  background: transparent;
  -webkit-overflow-scrolling: touch;
  outline: none;
  transition: transform 225ms cubic-bezier(0, 0, 0.2, 1) 0ms;

  /* Modal styles */

  /**
   * This means it won't be possible to close the modal by clicking the
   * overlay on mobile because the content will occupy all the surface of
   * the device.
   */
  .${modalOverlayModeClassNames.modal} & {
    transform: scale(0);

    ${({ theme }) => theme.fns.getMediaQuery({ maxWidth: 'md' })} {
      height: 100%;
      width: 100%;
    }
  }

  .ReactModal__Overlay--after-open.${modalOverlayModeClassNames.modal} & {
    transform: none;
  }

  .ReactModal__Overlay--before-close.${modalOverlayModeClassNames.modal} & {
    transform: scale(0);
  }

  /* Modal drawer styles */

  .${modalOverlayAnchorClassNames.left} & {
    height: 100%;
    transform: translate(-100%, 0);
  }

  .${modalOverlayAnchorClassNames.right} & {
    height: 100%;
    transform: translate(100%, 0);
  }

  .${modalOverlayAnchorClassNames.top} & {
    width: 100%;
    transform: translate(0, -100%);
  }

  .${modalOverlayAnchorClassNames.bottom} & {
    width: 100%;
    transform: translate(0, 100%);
  }

  .ReactModal__Overlay--after-open.${modalOverlayAnchorClassNames.left} & {
    transform: none;
  }

  .ReactModal__Overlay--before-close.${modalOverlayAnchorClassNames.left} & {
    transform: translate(-100%, 0);
  }

  .ReactModal__Overlay--after-open.${modalOverlayAnchorClassNames.right} & {
    transform: none;
  }

  .ReactModal__Overlay--before-close.${modalOverlayAnchorClassNames.right} & {
    transform: translate(100%, 0);
  }

  .ReactModal__Overlay--after-open.${modalOverlayAnchorClassNames.top} & {
    transform: none;
  }

  .ReactModal__Overlay--before-close.${modalOverlayAnchorClassNames.top} & {
    transform: translate(0, -100%);
  }

  .ReactModal__Overlay--after-open.${modalOverlayAnchorClassNames.bottom} & {
    transform: translate(0, 0);
  }

  .ReactModal__Overlay--before-close.${modalOverlayAnchorClassNames.bottom} & {
    transform: translate(0, 100%);
  }
`;

export const modalClassNames = {
  root: ModalRoot.toString().slice(1),
  overlay: ModalOverlay.toString().slice(1),
  content: ModalContent.toString().slice(1),
};

export const Modal: React.FC<ModalProps> = ({
  overlayRef,
  contentRef,
  mode = 'modal',
  anchor = 'bottom',
  closeTimeoutMS = 250,
  children,
  isOpen: isOpenProp,
  onRequestClose,
  ...otherProps
}) => {
  const isOpen = useModal({
    isOpen: isOpenProp,
    onRequestClose,
    closeTimeoutMS,
  });

  return (
    <>
      {isOpen && <ModalGlobalStyle />}
      <ModalRoot
        isOpen={isOpen}
        closeTimeoutMS={closeTimeoutMS}
        overlayElement={(
          { className, ...otherOverlayProps },
          contentElement,
        ) => (
          <ModalOverlay
            className={clsx(
              className,
              modalOverlayModeClassNames[mode],
              mode === 'drawer' && modalOverlayAnchorClassNames[anchor],
            )}
            {...otherOverlayProps}
          >
            {contentElement}
          </ModalOverlay>
        )}
        contentElement={(contentProps, children) => (
          <ModalContent {...contentProps}>{children}</ModalContent>
        )}
        overlayRef={(instance) => setRef(overlayRef, instance)}
        contentRef={(instance) => setRef(contentRef, instance)}
        onRequestClose={onRequestClose as ReactModal.Props['onRequestClose']}
        {...otherProps}
      >
        {children}
      </ModalRoot>
    </>
  );
};
