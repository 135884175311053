import React from 'react';
import styled, { css } from 'styled-components';
import clsx from 'clsx';

import { checkIOS } from '../../../utils/checkIOS';
import {
  modalClassNames,
  modalOverlayModeClassNames,
  modalOverlayAnchorClassNames,
} from '../Modal';
import { PaperRef, PaperProps } from './Paper.types';

const variantStandardClassName = 'Paper-variantStandard';
const variantOutlinedClassName = 'Paper-variantOutlined';

export const PaperRoot = styled.div`
  border-radius: 10px;
  background-color: var(--common-white);

  &.${variantStandardClassName} {
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.25);
  }

  &.${variantOutlinedClassName} {
    border: ${({ theme }) => theme.fns.getBorder('primary')};
  }

  /* This allows Paper component to play nicely with modals */

  .${modalOverlayModeClassNames.modal} > .${modalClassNames.content} > & {
    ${({ theme }) => theme.fns.getMediaQuery({ maxWidth: 'md' })} {
      border-radius: 0;
      height: 100%;
      width: 100%;

      /**
       * Adding overflow-y scroll to iOS devices is not needed and, in fact,
       * makes scrolling on modals glitchy. This condition is to avoid adding it
       * on iOS devices.
       */
      ${!checkIOS()
        ? css`
            overflow-y: scroll;
          `
        : ''}
    }
  }

  /* This allows Paper component to play nicely with drawers */

  /* stylelint-disable-next-line no-duplicate-selectors */
  .${modalOverlayAnchorClassNames.left} > .${modalClassNames.content} > & {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
    height: 100%;
  }

  /* stylelint-disable-next-line no-duplicate-selectors */
  .${modalOverlayAnchorClassNames.right} > .${modalClassNames.content} > & {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
    height: 100%;
  }

  /* stylelint-disable-next-line no-duplicate-selectors */
  .${modalOverlayAnchorClassNames.top} > .${modalClassNames.content} > & {
    border-top-left-radius: 0;
    border-top-right-radius: 0;
    width: 100%;
  }

  /* stylelint-disable-next-line no-duplicate-selectors */
  .${modalOverlayAnchorClassNames.bottom} > .${modalClassNames.content} > & {
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
    width: 100%;
  }
`;

export const paperClassNames = {
  root: PaperRoot.toString().slice(1),
  variantStandard: variantStandardClassName,
  variantOutlined: variantOutlinedClassName,
};

export const paperVariantClassNames = {
  standard: variantStandardClassName,
  outlined: variantOutlinedClassName,
};

export const Paper = React.forwardRef<PaperRef, PaperProps>(function Paper(
  { className, style, variant = 'standard', onClick, children, ...otherProps },
  ref,
) {
  return (
    <PaperRoot
      data-testid="Paper"
      ref={ref}
      className={clsx(className, paperVariantClassNames[variant])}
      style={style}
      onClick={onClick}
      {...otherProps}
    >
      {children}
    </PaperRoot>
  );
});
