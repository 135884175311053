import React, { forwardRef, useCallback } from 'react';
import styled from 'styled-components';
import clsx from 'clsx';

import { useControlled } from '../../../hooks/useControlled';
import { iconBaseClassNames } from '../../icons/IconBase';
import { SwitchBaseRef, SwitchBaseProps } from './SwitchBase.types';

const switchBaseRootDisabledClassName = 'SwitchBase-disabled';
const switchBaseRootCheckedClassName = 'SwitchBase-checked';
const switchBaseRootUncheckedClassName = 'SwitchBase-unchecked';

export const SwitchBaseRoot = styled.span`
  display: inline-flex;
  align-items: center;
  justify-content: center;
  position: relative;
  background-color: transparent;
  outline: 0;
  border: 0;
  margin: 0;
  cursor: pointer;
  user-select: none;
  vertical-align: middle;
  appearance: none;

  padding: ${({ theme }) => theme.fns.getSpacing(6)};
  color: var(--common-black);

  ${({ theme }) => theme.fns.getShapeStyles('round')}

  &:not(.${switchBaseRootDisabledClassName}):hover {
    background-color: ${({ theme }) =>
      theme.fns.getColor('common.black', 0.96)};
  }

  &.${switchBaseRootDisabledClassName} {
    cursor: not-allowed;

    > .${iconBaseClassNames.root} {
      color: var(--grey-4);
    }
  }

  &.${switchBaseRootUncheckedClassName} {
    color: var(--grey-2);
  }

  &.${switchBaseRootCheckedClassName} {
    color: var(--common-black);
  }
`;

export const SwitchBaseInput = styled.input`
  cursor: inherit;
  position: absolute;
  opacity: 0;
  width: 100%;
  height: 100%;
  top: 0px;
  left: 0px;
  margin: 0px;
  padding: 0px;
  z-index: 1;
`;

export const switchBaseClassNames = {
  root: SwitchBaseRoot.toString().slice(1),
  disabled: switchBaseRootDisabledClassName,
  checked: switchBaseRootCheckedClassName,
  unchecked: switchBaseRootUncheckedClassName,
};

export const SwitchBase = forwardRef<SwitchBaseRef, SwitchBaseProps>(
  function SwitchBase(
    {
      checked: checkedProp,
      checkedIcon,
      className,
      defaultChecked,
      disabled = false,
      icon,
      id,
      inputRef,
      name,
      onChange,
      required = false,
      style,
      tabIndex,
      type,
      value,
      ...otherProps
    },
    ref,
  ) {
    const [checked, setChecked] = useControlled<boolean>({
      controlled: checkedProp,
      default: Boolean(defaultChecked),
    });

    const handleChange = useCallback<NonNullable<typeof onChange>>(
      (event) => {
        // Workaround to avoid onChange handler executions when
        // event.preventDefault has been called inside the same element's onClick
        // handler.
        // https://github.com/facebook/react/issues/9023
        if (event.nativeEvent.defaultPrevented) {
          return;
        }

        setChecked(event.target.checked);

        if (onChange) {
          onChange(event);
        }
      },
      [onChange, setChecked],
    );

    return (
      <SwitchBaseRoot
        className={clsx(
          disabled && switchBaseRootDisabledClassName,
          checked
            ? switchBaseRootCheckedClassName
            : switchBaseRootUncheckedClassName,
          className,
        )}
        data-testid="SwitchBase"
        ref={ref}
        style={style}
        {...otherProps}
      >
        <SwitchBaseInput
          checked={checked}
          disabled={disabled}
          id={id}
          name={name}
          onChange={handleChange}
          ref={inputRef}
          required={required}
          tabIndex={tabIndex}
          type={type}
          value={value}
        />
        {checked ? checkedIcon : icon}
      </SwitchBaseRoot>
    );
  },
);
