import React, { ChangeEvent, forwardRef } from 'react';
import styled from 'styled-components';

import { useControlled } from '../../../hooks/useControlled';
import { useId } from '../../../hooks/useId';
import { FormGroup } from '../FormGroup';
import { RadioGroupRef, RadioGroupProps } from './RadioGroup.types';
import { RadioGroupContext } from './RadioGroup.context';

export const RadioGroupRoot = styled(FormGroup)``;

export const radioGroupClassNames = {
  root: RadioGroupRoot.toString().slice(1),
};

export const RadioGroup = forwardRef<RadioGroupRef, RadioGroupProps>(
  function RadioGroup(
    {
      children,
      defaultValue,
      name: nameProp,
      onChange,
      value: valueProp,
      ...otherProps
    },
    ref,
  ) {
    const [value, setValueState] = useControlled({
      controlled: valueProp,
      default: defaultValue,
    });

    const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
      setValueState(event.target.value);

      if (onChange) {
        onChange(event);
      }
    };

    const name = useId(nameProp);

    return (
      <RadioGroupContext.Provider
        value={{ name, onChange: handleChange, value }}
      >
        <RadioGroupRoot
          data-testid="RadioGroup"
          ref={ref}
          role="radiogroup"
          {...otherProps}
        >
          {children}
        </RadioGroupRoot>
      </RadioGroupContext.Provider>
    );
  },
);
