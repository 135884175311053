import React from 'react';
import styled from 'styled-components';

import { IconBase } from '../IconBase';
import { ViewIconRef, ViewIconProps } from './ViewIcon.types';

export const ViewIconRoot = styled(IconBase)``;

export const viewIconClassName = {
  root: ViewIconRoot.toString().slice(1),
};

export const ViewIcon = React.forwardRef<ViewIconRef, ViewIconProps>(
  function ViewIcon({ className, style, size, color, ...otherProps }, ref) {
    return (
      <ViewIconRoot
        data-testid="ViewIcon"
        ref={ref}
        className={className}
        style={style}
        size={size}
        color={color}
        {...otherProps}
      >
        <path
          d="M19.136 9.27612C14.1365 17.5764 5.0139 16.2089 1.00001 9.27626C6.09555 2.09354 14.1349 2.39056 19.136 9.27612Z"
          stroke="currentColor"
        />
        <circle cx="9.93629" cy="9.49342" r="2.93629" stroke="currentColor" />
      </ViewIconRoot>
    );
  },
);
