import React from 'react';
import styled from 'styled-components';

import { Stack } from '../../core/Stack';
import { FormHelperText } from '../FormHelperText';
import { FormGroupRef, FormGroupProps } from './FormGroup.types';

export const FormGroupRoot = styled.div`
  display: inline-flex;
  position: relative;
  border: 0;
  margin: 0;
  padding: 0;
  min-width: 0;
  flex-direction: column;
  vertical-align: top;
`;

export const FormGroupInputs = styled(Stack)``;

export const FormGroupFormHelperText = styled(FormHelperText)`
  margin: ${({ theme }) => theme.fns.getSpacing(0, 0, 0, 10)};
`;

export const formGroupClassNames = {
  root: FormGroupRoot.toString().slice(1),
  inputs: FormGroupInputs.toString().slice(1),
  formHelperText: FormGroupFormHelperText.toString().slice(1),
};

export const FormGroup = React.forwardRef<FormGroupRef, FormGroupProps>(
  function FormGroup(
    {
      className,
      style,
      row = false,
      error,
      helperText,
      children,
      ...otherProps
    },
    ref,
  ) {
    return (
      <FormGroupRoot
        data-testid="FormGroup"
        ref={ref}
        className={className}
        style={style}
        {...otherProps}
      >
        <FormGroupInputs direction={row ? 'row' : 'column'} wrap="wrap">
          {children}
        </FormGroupInputs>
        {helperText && (
          <FormGroupFormHelperText error={error}>
            {helperText}
          </FormGroupFormHelperText>
        )}
      </FormGroupRoot>
    );
  },
);
