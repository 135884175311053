import React from 'react';
import styled from 'styled-components';
import clsx from 'clsx';

import { CheckboxUncheckedIcon } from '../../icons/CheckboxUncheckedIcon';
import { CheckboxCheckedIcon } from '../../icons/CheckboxCheckedIcon';
import { SwitchBase } from '../SwitchBase';
import { CheckboxRef, CheckboxProps } from './Checkbox.types';

const checkboxRootDisabledClassName = 'Checkbox-disabled';

export const CheckboxRoot = styled(SwitchBase)``;

export const checkboxClassNames = {
  root: CheckboxRoot.toString().slice(1),
  disabled: checkboxRootDisabledClassName,
};

const icon = <CheckboxUncheckedIcon />;
const checkedIcon = <CheckboxCheckedIcon />;

export const Checkbox = React.forwardRef<CheckboxRef, CheckboxProps>(
  function Checkbox(
    {
      checked,
      className,
      defaultChecked,
      disabled = false,
      id,
      inputRef,
      name,
      onChange,
      required = false,
      style,
      tabIndex,
      value,
      ...otherProps
    },
    ref,
  ) {
    return (
      <CheckboxRoot
        checked={checked}
        checkedIcon={checkedIcon}
        className={clsx(disabled && checkboxRootDisabledClassName, className)}
        data-testid="Checkbox"
        defaultChecked={defaultChecked}
        disabled={disabled}
        icon={icon}
        id={id}
        inputRef={inputRef}
        name={name}
        onChange={onChange}
        ref={ref}
        required={required}
        style={style}
        tabIndex={tabIndex}
        type="checkbox"
        value={value}
        {...otherProps}
      />
    );
  },
);
