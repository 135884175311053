import React from 'react';

import { UseModalHook } from './Modal.types';
import { ModalManager, onlyOneOpeningStrategy } from './Modal.manager';

// Static instance, shared with all the components using this hook
export const manager = new ModalManager(onlyOneOpeningStrategy);

export const useModal: UseModalHook = ({
  isOpen: isOpenProp,
  onRequestClose,
  closeTimeoutMS,
}): boolean => {
  // Unique identifier for this modal instance
  const idRef = React.useRef<string | null>(null);
  const getId = () => {
    if (idRef.current === null) {
      idRef.current = manager.generateId();
    }
    return idRef.current;
  };

  // The delayed isOpen that will be returned to the component
  const [delayedIsOpen, setDelayedIsOpen] = React.useState(false);

  // Add, update or remove modal instance to the list of mounted instances
  React.useEffect(() => {
    const id = getId();
    manager.add({
      id,
      isOpen: isOpenProp,
      onRequestClose,
      closeTimeoutMS,
    });
    return () => manager.remove(id);
  }, [closeTimeoutMS, isOpenProp, onRequestClose]);

  // Open or close this modal instance
  React.useEffect(() => {
    if (isOpenProp) {
      const id = getId();

      // The opening of the current modal instance is delayed until
      // the opening strategy has been completed
      manager.open(id).then(() => {
        setDelayedIsOpen((prevDelayedIsOpen) => !prevDelayedIsOpen);
      });
    } else {
      // The closing of the modal instance can be done right away
      setDelayedIsOpen(false);
    }
  }, [isOpenProp]);

  return delayedIsOpen;
};
