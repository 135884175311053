import React from 'react';
import styled from 'styled-components';

import { accordionClassNames } from '../Accordion';
import {
  AccordionDetailsRef,
  AccordionDetailsProps,
} from './AccordionDetails.types';

export const AccordionDetailsRoot = styled.div`
  display: none;
  padding-bottom: 16px;

  .${accordionClassNames.expanded} & {
    display: block;
  }
`;

export const accordionDetailsClassNames = {
  root: AccordionDetailsRoot.toString().slice(1),
};

export const AccordionDetails = React.forwardRef<
  AccordionDetailsRef,
  AccordionDetailsProps
>(function AccordionDetails({ className, style, children }, ref) {
  return (
    <AccordionDetailsRoot
      data-testid="AccordionDetails"
      ref={ref}
      className={className}
      style={style}
    >
      {children}
    </AccordionDetailsRoot>
  );
});
