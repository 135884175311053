import { ChangeEventHandler, createContext } from 'react';

export interface RadioGroupContextValue {
  name: string | undefined;
  onChange: ChangeEventHandler<HTMLInputElement>;
  value: any;
}

export const RadioGroupContext = createContext<
  RadioGroupContextValue | undefined
>(undefined);

if (process.env.NODE_ENV !== 'production') {
  RadioGroupContext.displayName = 'RadioGroupContext';
}
