import * as React from 'react';

/**
 * @ignore - internal component.
 * @type {React.Context<{} | {expanded: boolean, toggle: (event: React.SyntheticEvent, expanded: boolean) => void}>}
 */
export const AccordionContext = React.createContext<{
  expanded?: boolean;
  toggle?: React.MouseEventHandler<HTMLDivElement>;
}>({});

if (process.env.NODE_ENV !== 'production') {
  AccordionContext.displayName = 'AccordionContext';
}
