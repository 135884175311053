import React from 'react';
import styled from 'styled-components';

import { IconBase } from '../IconBase';
import {
  CheckCircleIconRef,
  CheckCircleIconProps,
} from './CheckCircleIcon.types';

export const CheckCircleIconRoot = styled(IconBase)``;

export const checkCircleIconClassNames = {
  root: CheckCircleIconRoot.toString().slice(1),
};

export const CheckCircleIcon = React.forwardRef<
  CheckCircleIconRef,
  CheckCircleIconProps
>(function CheckCircleIcon(
  { className, style, size, color, ...otherProps },
  ref,
) {
  return (
    <CheckCircleIconRoot
      data-testid="CheckCircleIcon"
      ref={ref}
      className={className}
      style={style}
      size={size}
      color={color}
      {...otherProps}
    >
      <path
        d="M10.0001 0.714355C4.87998 0.714355 0.714355 4.87998 0.714355 10.0001C0.714355 15.1202 4.87998 19.2858 10.0001 19.2858C15.1202 19.2858 19.2858 15.1202 19.2858 10.0001C19.2858 4.87998 15.1202 0.714355 10.0001 0.714355ZM14.8327 6.88801L8.83266 14.0309C8.76684 14.1093 8.68494 14.1726 8.59252 14.2166C8.50009 14.2606 8.39929 14.2842 8.29694 14.2858H8.28489C8.18477 14.2857 8.08578 14.2647 7.99434 14.2239C7.90289 14.1831 7.82104 14.1236 7.75409 14.0492L5.18266 11.192C5.11735 11.1228 5.06655 11.0411 5.03324 10.952C4.99993 10.8628 4.98478 10.7679 4.98869 10.6727C4.99259 10.5776 5.01547 10.4842 5.05598 10.3981C5.09648 10.3119 5.1538 10.2348 5.22457 10.1711C5.29533 10.1074 5.3781 10.0585 5.46803 10.0273C5.55796 9.99605 5.65322 9.98311 5.74822 9.98922C5.84322 9.99533 5.93604 10.0204 6.02123 10.0629C6.10641 10.1054 6.18224 10.1645 6.24426 10.2367L8.26614 12.4831L13.7389 5.96927C13.8617 5.82734 14.0353 5.73942 14.2224 5.72451C14.4095 5.7096 14.5949 5.76891 14.7386 5.88961C14.8823 6.0103 14.9727 6.1827 14.9903 6.36952C15.0079 6.55635 14.9513 6.7426 14.8327 6.88801Z"
        fill="currentColor"
      />
    </CheckCircleIconRoot>
  );
});
