import * as React from 'react';
import styled from 'styled-components';
import clsx from 'clsx';

import { CheckboxProps } from '../Checkbox';
import {
  FormControlLabelRef,
  FormControlLabelProps,
} from './FormControlLabel.types';

const rootPlacementStartClassName = 'FormControlLabel-labelPlacementStart';
const rootPlacementEndClassName = 'FormControlLabel-labelPlacementEnd';
const rootDisabledClassName = 'FormControlLabel-disabled';

export const FormControlLabelRoot = styled.label`
  display: inline-flex;
  align-items: center;
  cursor: pointer;
  vertical-align: middle;

  &.${rootPlacementStartClassName} {
    flex-direction: row-reverse;
  }

  &.${rootPlacementEndClassName} {
    flex-direction: row;
  }

  &.${rootDisabledClassName} {
    cursor: not-allowed;
    color: var(--grey-4);
  }
`;

export const FormControlLabelLabel = styled.span`
  ${({ theme }) => theme.fns.getTypographyStyles('primary.r14')}
`;

export const formControlLabelMapLabelPlacementToClassName = {
  start: rootPlacementStartClassName,
  end: rootPlacementEndClassName,
};

export const formControlLabelClassNames = {
  root: FormControlLabelRoot.toString().slice(1),
  placementStart: rootPlacementStartClassName,
  placementEnd: rootPlacementEndClassName,
  disabled: rootDisabledClassName,
  label: FormControlLabelLabel.toString().slice(1),
};

export const FormControlLabel = React.forwardRef<
  FormControlLabelRef,
  FormControlLabelProps
>(function FormControlLabel(props, ref) {
  const { className, style, control, label, labelPlacement = 'end' } = props;

  // Override Checkbox props if the same props are provided to FormControlLabel
  const checkboxKeys: Array<keyof CheckboxProps> = [
    'id',
    'name',
    'defaultChecked',
    'checked',
    'value',
    'disabled',
    'required',
    'onChange',
  ];

  const controlProps = checkboxKeys.reduce<CheckboxProps>((acc, key) => {
    if (props[key] !== undefined) {
      return {
        ...acc,
        [key]: props[key],
      };
    }

    return acc;
  }, {});

  return (
    <FormControlLabelRoot
      data-testid="FormControlLabel"
      ref={ref}
      htmlFor={controlProps.id}
      className={clsx(
        labelPlacement &&
          formControlLabelMapLabelPlacementToClassName[labelPlacement],
        controlProps.disabled && rootDisabledClassName,
        className,
      )}
      style={style}
    >
      {React.cloneElement(control, controlProps)}
      <FormControlLabelLabel>{label}</FormControlLabelLabel>
    </FormControlLabelRoot>
  );
});
