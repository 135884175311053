import React from 'react';
import styled from 'styled-components';
import clsx from 'clsx';

import { cssBaselineClassNames } from '../CssBaseline';
import { buttonBaseClassNames } from '../ButtonBase';
import { standardButtonClassNames } from '../StandardButton';
import {
  StandardButtonGroupRef,
  StandardButtonGroupProps,
} from './StandardButtonGroup.types';

export const StandardButtonGroupRoot = styled.div`
  display: flex;

  .${buttonBaseClassNames.root}.${buttonBaseClassNames.withIcons}:not(.${cssBaselineClassNames.fullWidth}, :disabled) {
    font-weight: 700;
  }

  .${standardButtonClassNames.variantPrimaryBlack},
    .${standardButtonClassNames.variantPrimaryWhite} {
    &:disabled {
      font-weight: 400;
      background: var(--grey-7);
      border-color: var(--grey-4);
      color: var(--grey-4);
    }

    &:not(:disabled),
    &:disabled {
      border-radius: 0;
      border-right-width: 0;

      &:first-child {
        border-top-left-radius: 5px;
        border-bottom-left-radius: 5px;
      }

      &:last-child {
        border-top-right-radius: 5px;
        border-bottom-right-radius: 5px;
        border-right-width: 1px;
      }
    }
  }

  &.${cssBaselineClassNames.fullWidth} .${standardButtonClassNames.root} {
    flex-grow: 1;
  }
`;

export const standardButtonGroupClassNames = {
  root: StandardButtonGroupRoot.toString().slice(1),
};

export const StandardButtonGroup = React.forwardRef<
  StandardButtonGroupRef,
  StandardButtonGroupProps
>(function StandardButtonGroup({ className, style, fullWidth, children }, ref) {
  return (
    <StandardButtonGroupRoot
      data-testid="StandardButtonGroup"
      ref={ref}
      className={clsx(className, fullWidth && cssBaselineClassNames.fullWidth)}
      style={style}
    >
      {children}
    </StandardButtonGroupRoot>
  );
});
