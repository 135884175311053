import React, { forwardRef, useRef, useCallback, useState } from 'react';
import styled from 'styled-components';
import clsx from 'clsx';

import { cssBaselineClassNames } from '../../core/CssBaseline';
import { ButtonBase, ButtonBaseProps } from '../../core/ButtonBase';
import { useControlled } from '../../../hooks/useControlled';
import { Input, inputClassNames } from '../Input';
import {
  RegistryItemNoteRef,
  RegistryItemNoteProps,
} from './RegistryItemNote.types';
import { useForkRef } from '../../../hooks';

const isFocusedClassName = 'RegistryItemNote-isFocused';
const isHeadingVisibleClassName = 'RegistryItemNote-isHeadingVisible';
const isButtonVisibleClassName = 'RegistryItemNote-isButtonVisible';

export const RegistryItemNoteRoot = styled.div`
  position: relative;
  display: inline-block;
  min-width: 290px;
`;

export const RegistryItemNoteInput = styled(Input)`
  width: 100%;

  .${inputClassNames.textarea} {
    padding: 12px;
    background-color: var(--grey-5);
    border: 1px solid var(--grey-5);

    ${({ theme }) => theme.fns.getShapeStyles('soft')}
    ${({ theme }) => theme.fns.getTypographyStyles('primary.r14')}

    &:focus {
      background-color: var(--common-white);
      border: ${({ theme }) => theme.fns.getBorder('secondary')};
    }

    &:hover {
      border: ${({ theme }) => theme.fns.getBorder('primary')};
    }
  }

  /* stylelint-disable-next-line selector-type-no-unknown */
  ${RegistryItemNoteRoot}:not(.${isFocusedClassName}) & .${inputClassNames.count} {
    display: none;
  }

  .${isHeadingVisibleClassName} & .${inputClassNames.textarea} {
    padding-top: 36px;
  }

  .${isButtonVisibleClassName} & .${inputClassNames.textarea} {
    padding-right: 58px;
  }
`;

export const RegistryItemNoteHeading = styled.label`
  position: absolute;
  top: 1px;
  left: 1px;
  padding: 12px 12px 0;
  width: 100%;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;

  ${({ theme }) => theme.fns.getTypographyStyles('primary.l14')}
`;

export const RegistryItemNoteSaveButton = styled(ButtonBase)`
  position: absolute;
  top: 1px;
  bottom: 19px;
  right: 1px;
  padding: 0 8px;
  min-width: 0;
  border: 0;
  border-left: ${({ theme }) => theme.fns.getBorder('secondary')};

  ${({ theme }) => theme.fns.getShapeStyles('soft')}
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;

  ${({ theme }) => theme.fns.getTypographyStyles('primary.m14')}
`;

export const registryItemNoteClassNames = {
  root: RegistryItemNoteRoot.toString().slice(1),
  isHeadingVisible: isHeadingVisibleClassName,
  isButtonVisible: isButtonVisibleClassName,
  input: RegistryItemNoteInput.toString().slice(1),
  heading: RegistryItemNoteHeading.toString().slice(1),
  saveButton: RegistryItemNoteSaveButton.toString().slice(1),
};

let idCount = 0;
const getId = () => {
  return `registry-item-note-${idCount++}`;
};

export const RegistryItemNote = forwardRef<
  RegistryItemNoteRef,
  RegistryItemNoteProps
>(function RegistryItemNote(
  {
    id,
    name,
    className,
    style,
    autoFocus = false,
    disabled = false,
    defaultValue,
    fullWidth,
    heading,
    placeholder,
    required,
    value: valueProp,
    inputRef,
    onBlur,
    onChange,
    onCopy,
    onDrop,
    onFocus,
    onPaste,
    onSave,
  },
  ref,
) {
  // HTML id used to link the heading label to the input component so that when
  // we click on the label the input is focused.
  const { current: finalId } = useRef(id ?? getId());

  // Keep track of the textarea's internal value regardless of whether it's
  // controlled or uncontrolled.
  const [value, setValue] = useControlled<NonNullable<typeof valueProp>>({
    controlled: valueProp,
    default: defaultValue,
  });
  const handleChange = useCallback<NonNullable<typeof onChange>>(
    (event) => {
      setValue(event.target.value);
      onChange?.(event);
    },
    [onChange, setValue],
  );

  // Keep track of when the textarea is focused so that we can add the right
  // CSS class.
  const [isFocused, setIsFocused] = useState(false);
  const handleFocus = useCallback<NonNullable<typeof onFocus>>(
    (event) => {
      setIsFocused(true);
      onFocus?.(event);
    },
    [onFocus, setIsFocused],
  );
  const handleBlur = useCallback<NonNullable<typeof onBlur>>(
    (event) => {
      setIsFocused(false);
      onBlur?.(event);
    },
    [onBlur, setIsFocused],
  );

  // Hack to avoid losing input's focus when clicking on the save button.
  const handleSaveButtonMouseDown = useCallback<
    NonNullable<ButtonBaseProps['onMouseDown']>
  >((event) => {
    event.preventDefault();
  }, []);

  // Handle save and keep the last saved value.
  const internalInputRef = useRef<HTMLTextAreaElement>(null);
  const mergedInputRef = useForkRef<HTMLTextAreaElement>(
    internalInputRef,
    inputRef,
  );
  const [savedValue, setSavedValue] = useState(value);
  const [saving, setSaving] = useState(false);
  const handleSave = useCallback<NonNullable<ButtonBaseProps['onClick']>>(
    async (event) => {
      if (!onSave) return;

      // 1. set saving to true to mark save button as loading,
      setSaving(true);

      const currentValue = internalInputRef.current?.value ?? '';

      try {
        // 2. call onSave and await for its response,
        await onSave(event, currentValue);

        // 3. update current saved value,
        setSavedValue(currentValue);

        // 4. set saving to false to update the save button state,
        setSaving(false);

        // 5. and blur the textarea so that the button disappears.
        internalInputRef.current?.blur();
        setIsFocused(false);
      } catch (error) {
        // 4. set saving to false to update the save button state,
        setSaving(false);

        // 5. and blur the textarea so that the button disappears.
        internalInputRef.current?.blur();
        setIsFocused(false);

        // Rethrow error so that users can treat it as they want.
        throw error;
      }
    },
    [onSave],
  );

  const isEmpty = !value;
  const isSaved = savedValue === value;
  const isHeadingVisible = heading && !isFocused && !isEmpty;
  const isButtonVisible = onSave && isFocused;

  return (
    <RegistryItemNoteRoot
      ref={ref}
      data-testid="RegistryItemNote"
      className={clsx(
        fullWidth && cssBaselineClassNames.fullWidth,
        isFocused && isFocusedClassName,
        isHeadingVisible && isHeadingVisibleClassName,
        isButtonVisible && isButtonVisibleClassName,
        className,
      )}
      style={style}
    >
      <RegistryItemNoteInput
        inputRef={mergedInputRef}
        id={finalId}
        name={name}
        autoComplete="off"
        autoFocus={autoFocus}
        disabled={saving || disabled}
        fullWidth={fullWidth}
        multiline
        placeholder={placeholder}
        required={required}
        maxLength={500}
        type="text"
        value={value}
        onBlur={handleBlur}
        onChange={handleChange}
        onCopy={onCopy}
        onDrop={onDrop}
        onFocus={handleFocus}
        onPaste={onPaste}
      />
      {isHeadingVisible && (
        <RegistryItemNoteHeading htmlFor={finalId}>
          {heading}
        </RegistryItemNoteHeading>
      )}
      {isButtonVisible && (
        <RegistryItemNoteSaveButton
          data-testid="RegistryItemNoteSaveButton"
          color="common.white"
          disabled={isSaved}
          loading={saving}
          onMouseDown={handleSaveButtonMouseDown}
          onClick={handleSave}
        >
          Save
        </RegistryItemNoteSaveButton>
      )}
    </RegistryItemNoteRoot>
  );
});
