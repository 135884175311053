import { CheckIOSUtil } from './checkIOS.types';

// Cache to avoid subsequent calculations
let isIOS: boolean | null = null;

/**
 * Checks whether the current device is running on iOS
 * https://stackoverflow.com/a/9039885
 *
 * @returns {boolean}
 */
export const checkIOS: CheckIOSUtil = (): boolean => {
  if (isIOS === null) {
    isIOS =
      [
        'iPad Simulator',
        'iPhone Simulator',
        'iPod Simulator',
        'iPad',
        'iPhone',
        'iPod',
      ].includes(navigator.platform) ||
      // iPad on iOS 13 detection
      (navigator.userAgent.includes('Mac') && 'ontouchend' in document);
  }

  return isIOS;
};
