import { SetRefUtil } from './setRef.types';

/**
 * Passes {value} to {ref}.
 *
 * @param ref A ref callback or ref object. If anything falsy, this is a no-op.
 * @param value The value we want to pass to the ref.
 */
export const setRef: SetRefUtil = (ref, value) => {
  if (typeof ref === 'function') {
    ref(value);
  } else if (ref) {
    ref.current = value;
  }
};
