import React from 'react';
import styled from 'styled-components';

import { IconBase } from '../IconBase';
import { DeleteIconRef, DeleteIconProps } from './DeleteIcon.types';

export const DeleteIconRoot = styled(IconBase)``;

export const deleteIconClassNames = {
  root: DeleteIconRoot.toString().slice(1),
};

export const DeleteIcon = React.forwardRef<DeleteIconRef, DeleteIconProps>(
  function DeleteIcon({ className, style, size, color, ...otherProps }, ref) {
    return (
      <DeleteIconRoot
        data-testid="DeleteIcon"
        ref={ref}
        className={className}
        style={style}
        size={size}
        color={color}
        {...otherProps}
      >
        <path
          d="M2.75 5.19946H4.36111H17.25"
          strokeLinecap="round"
          strokeLinejoin="round"
          stroke="currentColor"
        />
        <path
          d="M6.77848 5.2V3.6C6.77848 3.17565 6.94823 2.76869 7.25037 2.46863C7.55251 2.16857 7.9623 2 8.38959 2H11.6118C12.0391 2 12.4489 2.16857 12.751 2.46863C13.0532 2.76869 13.2229 3.17565 13.2229 3.6V5.2M15.6396 5.2V16.4C15.6396 16.8243 15.4699 17.2313 15.1677 17.5314C14.8656 17.8314 14.4558 18 14.0285 18H5.97293C5.54563 18 5.13584 17.8314 4.8337 17.5314C4.53156 17.2313 4.36182 16.8243 4.36182 16.4V5.2H15.6396Z"
          strokeLinecap="round"
          strokeLinejoin="round"
          stroke="currentColor"
        />
        <path
          d="M8.38818 9.19995V14"
          strokeLinecap="round"
          strokeLinejoin="round"
          stroke="currentColor"
        />
        <path
          d="M11.6118 9.19995V14"
          strokeLinecap="round"
          strokeLinejoin="round"
          stroke="currentColor"
        />
      </DeleteIconRoot>
    );
  },
);
