import React from 'react';
import styled from 'styled-components';
import clsx from 'clsx';

import { ButtonBase, buttonBaseClassNames } from '../ButtonBase';
import { TabRef, TabProps } from './Tab.types';

const tabSelectedClassName = 'Tab-selected';

export const TabRoot = styled(ButtonBase)`
  position: relative;
  min-height: 26px;
  min-width: auto;
  flex-shrink: 0;
  padding: 4px 0;
  overflow: hidden;
  white-space: normal;
  text-align: center;
  background-color: inherit;
  color: var(--grey-3);
  border-bottom: 2px solid transparent;

  ${({ theme }) => theme.fns.getTypographyStyles('primary.r14')}

  &.${buttonBaseClassNames.withIcons} {
    padding: 4px 0;
  }

  &.${tabSelectedClassName} {
    color: var(--common-black);
    border-bottom-color: var(--common-black);
  }

  .${buttonBaseClassNames.label} {
    text-transform: uppercase;
  }
`;

export const tabClassNames = {
  root: TabRoot.toString().slice(1),
  selected: tabSelectedClassName,
};

export const Tab = React.forwardRef<TabRef, TabProps>(function Tab(
  {
    className,
    style,
    value,
    selected,
    onClick,
    onChange,
    children,
    ...otherProps
  },
  ref,
) {
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>): void => {
    if (!selected && onChange) {
      onChange(event, value);
    }

    if (onClick) {
      onClick(event);
    }
  };

  return (
    <TabRoot
      data-testid="Tab"
      ref={ref}
      className={clsx(className, selected && tabSelectedClassName)}
      style={style}
      role="tab"
      aria-selected={selected}
      tabIndex={selected ? 0 : -1}
      onClick={handleClick}
      {...otherProps}
    >
      {children}
    </TabRoot>
  );
});
