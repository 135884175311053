import React from 'react';
import styled from 'styled-components';
import clsx from 'clsx';

import { cssBaselineClassNames } from '../../core/CssBaseline';
import { Input } from '../Input';
import { FormHelperText } from '../FormHelperText';
import { TextFieldRef, TextFieldProps } from './TextField.types';

export const TextFieldRoot = styled.div`
  display: inline-flex;
  position: relative;
  border: 0;
  margin: 0;
  padding: 0;
  min-width: 0;
  flex-direction: column;
  vertical-align: top;
`;

export const TextFieldFormHelperText = styled(FormHelperText)`
  margin: ${({ theme }) => theme.fns.getSpacing(4, 0, 0, 10)};
`;

export const textFieldClassNames = {
  root: TextFieldRoot.toString().slice(1),
  formHelperText: TextFieldFormHelperText.toString().slice(1),
};

export const TextField = React.forwardRef<TextFieldRef, TextFieldProps>(
  function TextField(
    {
      id,
      name,
      className,
      style,
      autoComplete,
      autoFocus = false,
      disabled = false,
      defaultValue,
      error,
      fullWidth,
      helperText,
      maxLength,
      multiline,
      placeholder,
      required,
      rows,
      minRows,
      maxRows,
      type = 'text',
      value,
      inputRef,
      onChange,
      onCopy,
      onPaste,
      onDrop,
    },
    ref,
  ) {
    return (
      <TextFieldRoot
        ref={ref}
        data-testid="TextField"
        className={clsx(
          fullWidth && cssBaselineClassNames.fullWidth,
          className,
        )}
        style={style}
      >
        <Input
          inputRef={inputRef}
          id={id}
          name={name}
          autoComplete={autoComplete}
          autoFocus={autoFocus}
          disabled={disabled}
          fullWidth={fullWidth}
          multiline={multiline}
          placeholder={placeholder}
          required={required}
          rows={rows}
          maxLength={maxLength}
          minRows={minRows}
          maxRows={maxRows}
          type={type}
          defaultValue={defaultValue}
          value={value}
          onChange={onChange}
          onCopy={onCopy}
          onPaste={onPaste}
          onDrop={onDrop}
        />
        {helperText && (
          <TextFieldFormHelperText error={error}>
            {helperText}
          </TextFieldFormHelperText>
        )}
      </TextFieldRoot>
    );
  },
);
