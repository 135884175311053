import React from 'react';
import styled, { css } from 'styled-components';
import clsx from 'clsx';
import TextareaAutosize from 'react-textarea-autosize';

import { useControlled } from '../../../hooks/useControlled';
import { cssBaselineClassNames } from '../../core/CssBaseline';
import { InputRef, InputProps, MultilineOnlyInputProps } from './Input.types';

export const InputRoot = styled.div`
  border: 0;
  margin: 0;
  display: inline-flex;
  padding: 0;
  position: relative;
  min-width: 0;
  flex-direction: column;
  vertical-align: top;
`;

const inputStyleFn = css`
  outline: 0px;
  padding: ${({ theme }) => theme.fns.getSpacing(7, 10)};
  border: ${({ theme }) => theme.fns.getBorder('secondary')};

  ${({ theme }) => theme.fns.getShapeStyles('main')}
  ${({ theme }) => theme.fns.getTypographyStyles('primary.r16')}

    &::placeholder {
    color: var(--grey-2);
  }

  &:focus::placeholder {
    color: var(--grey-3);
  }

  &:hover,
  &:focus {
    border: ${({ theme }) => theme.fns.getBorder('primary')};
  }

  &:disabled,
  &:disabled:hover,
  &:disabled:focus {
    cursor: not-allowed;
    border-color: var(--grey-5);
    color: var(--grey-2);
  }
`;

export const InputInput = styled.input`
  ${inputStyleFn}
`;

export const InputTextarea = styled(TextareaAutosize)`
  resize: none;
  ${inputStyleFn}
`;

export const InputCount = styled.p`
  margin: 0;
  text-align: right;

  ${({ theme }) => theme.fns.getTypographyStyles('primary.r12')}
`;

export const inputClassNames = {
  root: InputRoot.toString().slice(1),
  input: InputInput.toString().slice(1),
  textarea: InputTextarea.toString().slice(1),
  count: InputCount.toString().slice(1),
};

export function capMultilineOnlyInputProps({
  rows,
  minRows,
  maxRows,
}: MultilineOnlyInputProps): MultilineOnlyInputProps {
  /**
   * Neither rows, minRows or maxRows can't be less than 1.
   * When rows is present it is used as minRows.
   * minRows can't never be less than the final minRows (whether it comes from
   * rows or the actual minRows prop).
   */
  const cappedRows = rows && Math.max(1, rows);
  const cappedMinRows = cappedRows ?? (minRows && Math.max(1, minRows));
  const cappedMaxRows = maxRows && Math.max(cappedMinRows ?? 1, maxRows);

  return {
    rows: cappedRows,
    minRows: cappedMinRows,
    maxRows: cappedMaxRows,
  };
}

export const Input = React.forwardRef<InputRef, InputProps>(function Input(
  {
    id,
    name,
    className,
    style,
    autoComplete,
    autoFocus = false,
    disabled = false,
    defaultValue,
    fullWidth,
    maxLength,
    multiline = false,
    placeholder,
    required,
    type = 'text',
    value: valueProp,
    rows,
    minRows,
    maxRows,
    inputRef,
    onBlur,
    onChange,
    onCopy,
    onDrop,
    onFocus,
    onPaste,
  },
  ref,
) {
  const [value, setValue] = useControlled<NonNullable<typeof valueProp>>({
    controlled: valueProp,
    default: defaultValue,
  });
  const [count, setCount] = React.useState(
    `${value ?? defaultValue ?? ''}`.length,
  );

  const handleChange = React.useCallback<NonNullable<typeof onChange>>(
    (event) => {
      if (maxLength !== undefined) {
        setCount(event.target.value.length);
      }

      setValue(event.target.value);

      if (onChange) {
        onChange(event);
      }
    },
    [maxLength, onChange, setValue],
  );

  const commonProps = {
    id,
    name,
    autoComplete,
    autoFocus,
    disabled,
    placeholder,
    required,
    value,
    maxLength,
    onChange: handleChange,
    onCopy,
    onPaste,
    onDrop,
    onBlur,
    onFocus,
  };

  return (
    <InputRoot
      data-testid="Input"
      ref={ref}
      className={clsx(fullWidth && cssBaselineClassNames.fullWidth, className)}
      style={style}
    >
      {multiline ? (
        <InputTextarea
          ref={inputRef}
          className={clsx(fullWidth && cssBaselineClassNames.fullWidth)}
          {...commonProps}
          {...capMultilineOnlyInputProps({ rows, minRows, maxRows })}
        />
      ) : (
        <InputInput
          ref={inputRef}
          className={clsx(
            fullWidth && cssBaselineClassNames.fullWidth,
            className,
          )}
          type={type}
          {...commonProps}
        />
      )}
      {maxLength !== undefined && (
        <InputCount>{`${count}/${maxLength}`}</InputCount>
      )}
    </InputRoot>
  );
});
