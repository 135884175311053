import React from 'react';
import styled from 'styled-components';

import { IconBase } from '../IconBase';
import { ExtensionIconRef, ExtensionIconProps } from './ExtensionIcon.types';

export const ExtensionIconRoot = styled(IconBase)``;

export const extensionIconClassNames = {
  root: ExtensionIconRoot.toString().slice(1),
};

export const ExtensionIcon = React.forwardRef<
  ExtensionIconRef,
  ExtensionIconProps
>(function ExtensionIcon(
  { className, style, size, color, ...otherProps },
  ref,
) {
  return (
    <ExtensionIconRoot
      data-testid="ExtensionIcon"
      ref={ref}
      className={className}
      style={style}
      size={size}
      color={color}
      {...otherProps}
    >
      <path
        d="M17.0827 9.16732H15.8327V5.83398C15.8327 4.91732 15.0827 4.16732 14.166 4.16732H10.8327V2.91732C10.8327 1.76732 9.89935 0.833984 8.74935 0.833984C7.59935 0.833984 6.66602 1.76732 6.66602 2.91732V4.16732H3.33268C2.41602 4.16732 1.67435 4.91732 1.67435 5.83398V9.00065H2.91602C4.15768 9.00065 5.16602 10.009 5.16602 11.2507C5.16602 12.4923 4.15768 13.5007 2.91602 13.5007H1.66602V16.6673C1.66602 17.584 2.41602 18.334 3.33268 18.334H6.49935V17.084C6.49935 15.8423 7.50768 14.834 8.74935 14.834C9.99102 14.834 10.9993 15.8423 10.9993 17.084V18.334H14.166C15.0827 18.334 15.8327 17.584 15.8327 16.6673V13.334H17.0827C18.2327 13.334 19.166 12.4007 19.166 11.2507C19.166 10.1007 18.2327 9.16732 17.0827 9.16732Z"
        fill="currentColor"
      />
    </ExtensionIconRoot>
  );
});
