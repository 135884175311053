import React from 'react';
import styled from 'styled-components';

import { IconBase } from '../IconBase';
import { AppleIconRef, AppleIconProps } from './AppleIcon.types';

export const AppleIconRoot = styled(IconBase)``;

export const appleIconClassNames = {
  root: AppleIconRoot.toString().slice(1),
};

export const AppleIcon = React.forwardRef<AppleIconRef, AppleIconProps>(
  function AppleIcon({ className, style, size, color, ...otherProps }, ref) {
    return (
      <AppleIconRoot
        data-testid="AppleIcon"
        ref={ref}
        className={className}
        style={style}
        size={size}
        color={color}
        {...otherProps}
      >
        <path
          d="M13.6385 5.34619C12.0635 5.34619 11.3979 6.09775 10.301 6.09775C9.1764 6.09775 8.31858 5.35166 6.95374 5.35166C5.6178 5.35166 4.19319 6.16729 3.28851 7.55674C2.01819 9.51611 2.23382 13.2063 4.29124 16.3501C5.02718 17.4755 6.00999 18.7376 7.29905 18.7513H7.32249C8.4428 18.7513 8.77561 18.0177 10.3174 18.0091H10.3408C11.8596 18.0091 12.1643 18.747 13.2799 18.747H13.3033C14.5924 18.7333 15.628 17.3349 16.3639 16.2138C16.8936 15.4075 17.0905 15.0028 17.4967 14.0907C14.5205 12.961 14.0424 8.74189 16.9858 7.12432C16.0873 5.99932 14.8248 5.34775 13.6346 5.34775L13.6385 5.34619Z"
          fill="currentColor"
        />
        <path
          d="M13.2915 1.25C12.354 1.31367 11.2602 1.91055 10.6196 2.68984C10.0383 3.39609 9.5602 4.44375 9.7477 5.45976H9.8227C10.8211 5.45976 11.843 4.85859 12.4399 4.08828C13.0149 3.35508 13.4508 2.31602 13.2915 1.25Z"
          fill="currentColor"
        />
      </AppleIconRoot>
    );
  },
);
