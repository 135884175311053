import React from 'react';
import styled from 'styled-components';

import { IconBase } from '../IconBase';
import {
  LoadingMoreCircleIconRef,
  LoadingMoreCircleIconProps,
} from './LoadingMoreCircleIcon.types';

export const LoadingMoreCircleIconRoot = styled(IconBase)``;

export const loadingMoreCircleIconClassNames = {
  root: LoadingMoreCircleIconRoot.toString().slice(1),
};

export const LoadingMoreCircleIcon = React.forwardRef<
  LoadingMoreCircleIconRef,
  LoadingMoreCircleIconProps
>(function LoadingMoreCircleIcon(
  { className, style, size, color, ...otherProps },
  ref,
) {
  return (
    <LoadingMoreCircleIconRoot
      data-testid="LoadingMoreCircleIcon"
      ref={ref}
      className={className}
      style={style}
      size={size}
      color={color}
      {...otherProps}
    >
      <rect
        x="0.5"
        y="0.5"
        width="19"
        height="19"
        rx="9.5"
        stroke="currentColor"
      />
      <circle r="1" cx="5" cy="10" fill="currentColor">
        <animate
          attributeName="cy"
          values="10;7;10;10;10"
          dur="2s"
          repeatCount="indefinite"
        />
      </circle>
      <circle r="1" cx="10" cy="10" fill="currentColor">
        <animate
          attributeName="cy"
          values="10;10;7;10;10"
          dur="2s"
          repeatCount="indefinite"
        />
      </circle>
      <circle r="1" cx="15" cy="10" fill="currentColor">
        <animate
          attributeName="cy"
          values="10;10;10;7;10"
          dur="2s"
          repeatCount="indefinite"
        />
      </circle>
    </LoadingMoreCircleIconRoot>
  );
});
