import React from 'react';
import styled from 'styled-components';

import { IconBase } from '../IconBase';
import {
  RadioCheckedIconRef,
  RadioCheckedIconProps,
} from './RadioCheckedIcon.types';

export const RadioCheckedIconRoot = styled(IconBase)``;

export const radioCheckedIconClassNames = {
  root: RadioCheckedIconRoot.toString().slice(1),
};

export const RadioCheckedIcon = React.forwardRef<
  RadioCheckedIconRef,
  RadioCheckedIconProps
>(function RadioCheckedIcon(
  { className, style, size, color, ...otherProps },
  ref,
) {
  return (
    <RadioCheckedIconRoot
      data-testid="RadioCheckedIcon"
      ref={ref}
      className={className}
      style={style}
      size={size}
      color={color}
      {...otherProps}
    >
      <rect
        x="1.5"
        y="1.5"
        width="17"
        height="17"
        rx="8.5"
        stroke="currentColor"
      />
      <rect
        x="4.5"
        y="4.5"
        width="11"
        height="11"
        rx="5.5"
        fill="currentColor"
        stroke="currentColor"
      />
    </RadioCheckedIconRoot>
  );
});
