import React from 'react';
import { isFragment } from 'react-is';
import styled from 'styled-components';
import clsx from 'clsx';

import { cssBaselineClassNames } from '../CssBaseline';
import { isValueSelected } from './isValueSelected.util';
import {
  ToggleButtonGroupRef,
  ToggleButtonGroupProps,
} from './ToggleButtonGroup.types';

export const ToggleButtonGroupRoot = styled.div`
  display: inline-flex;
  background: var(--grey-7);
  border: ${({ theme }) => theme.fns.getBorder('secondary')};
  ${({ theme }) => theme.fns.getShapeStyles('soft')}
`;

export const toggleButtonGroupClassNames = {
  root: ToggleButtonGroupRoot.toString().slice(1),
};

export const ToggleButtonGroup = React.forwardRef<
  ToggleButtonGroupRef,
  ToggleButtonGroupProps
>(function ToggleButtonGroup(
  { className, style, fullWidth, value, children, onChange },
  ref,
) {
  const handleChange: ToggleButtonGroupProps['onChange'] = (
    event,
    buttonValue,
  ) => {
    if (!onChange) {
      return;
    }

    onChange(event, value === buttonValue ? null : buttonValue);
  };
  return (
    <ToggleButtonGroupRoot
      data-testid="ToggleButtonGroup"
      ref={ref}
      className={clsx(className, fullWidth && cssBaselineClassNames.fullWidth)}
      style={style}
    >
      {React.Children.map(children, (child) => {
        if (!React.isValidElement(child)) {
          return null;
        }

        if (process.env.NODE_ENV !== 'production' && isFragment(child)) {
          console.error(
            [
              "The ToggleButtonGroup component doesn't accept a Fragment as a child.",
              'Consider providing an array instead.',
            ].join('\n'),
          );
        }

        return React.cloneElement(child, {
          onChange: handleChange,
          selected:
            child.props.selected === undefined
              ? isValueSelected(child.props.value, value)
              : child.props.selected,
          fullWidth,
        });
      })}
    </ToggleButtonGroupRoot>
  );
});
