import React from 'react';
import styled from 'styled-components';

import { IconBase } from '../IconBase';
import { MoonsiftLogoRef, MoonsiftLogoProps } from './MoonsiftLogo.types';

export const MoonsiftLogoRoot = styled(IconBase)``;

export const MoonsiftLogoClassNames = {
  root: MoonsiftLogoRoot.toString().slice(1),
};

export const MoonsiftLogo = React.forwardRef<
  MoonsiftLogoRef,
  MoonsiftLogoProps
>(function MoonsiftLogo({ className, style, size, color, ...otherProps }, ref) {
  return (
    <MoonsiftLogoRoot
      data-testid="MoonsiftLogo"
      ref={ref}
      className={className}
      style={style}
      size={size}
      color={color}
      {...otherProps}
    >
      <circle cx="10" cy="10" r="10" fill="currentColor" />
      <ellipse
        cx="10.0006"
        cy="10.0006"
        rx="8.46154"
        ry="8.46154"
        fill="white"
      />
      <ellipse
        cx="16.6673"
        cy="15.3841"
        rx="3.33334"
        ry="3.33333"
        fill="white"
      />
      <ellipse
        cx="16.6665"
        cy="15.3833"
        rx="2.05128"
        ry="2.05128"
        fill="#FDCA47"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M11.8189 3.48275C8.95796 4.12864 7.43555 6.80149 7.43555 10.0007C7.43555 13.1998 8.95796 15.8727 11.8189 16.5185C11.3565 16.616 10.8767 16.6673 10.3847 16.6673C6.63201 16.6673 3.58984 13.6826 3.58984 10.0007C3.58984 6.31875 6.63201 3.33398 10.3847 3.33398C10.8767 3.33398 11.3565 3.38529 11.8189 3.48275Z"
        fill="currentColor"
      />
    </MoonsiftLogoRoot>
  );
});
