import React from 'react';
import { useField } from 'formik';

import { Radio } from './Radio.component';
import { RadioRef, ControlledRadioProps } from './Radio.types';

export const ControlledRadio = React.forwardRef<RadioRef, ControlledRadioProps>(
  function ControlledRadio(
    {
      id,
      name: nameProp,
      className,
      style,
      defaultChecked,
      checked: checkedProp,
      value: valueProp,
      disabled = false,
      required = false,
      inputRef,
      onChange: onChangeProp,
    },
    ref,
  ) {
    const [{ name, onChange, value, checked }] = useField({
      type: 'radio',
      id,
      name: nameProp,
      className,
      style,
      defaultChecked,
      checked: checkedProp,
      value: valueProp,
      disabled,
      required,
      ref: inputRef,
      onChange: onChangeProp,
    });

    return (
      <Radio
        ref={ref}
        id={id}
        className={className}
        style={style}
        name={name}
        value={value}
        checked={checked}
        onChange={onChange}
      />
    );
  },
);
