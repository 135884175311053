import { useContext } from 'react';
import {
  RadioGroupContext,
  RadioGroupContextValue,
} from './RadioGroup.context';

export interface RadioGroupState extends RadioGroupContextValue {}

export function useRadioGroup(): RadioGroupState | undefined {
  return useContext(RadioGroupContext);
}
