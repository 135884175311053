import React from 'react';

import { UseLoadedHook, UseLoadedHookState } from './useLoaded.types';

export const useLoaded: UseLoadedHook = ({
  crossOrigin,
  referrerPolicy,
  src,
  srcSet,
}) => {
  const [loaded, setLoaded] = React.useState<UseLoadedHookState>('loading');

  React.useEffect(() => {
    if (!src && !srcSet) {
      return undefined;
    }

    setLoaded('loading');

    let active = true;
    const image = new Image();

    image.onload = () => {
      if (!active) {
        return;
      }
      setLoaded('loaded');
    };

    image.onerror = () => {
      if (!active) {
        return;
      }
      setLoaded('error');
    };

    image.crossOrigin = crossOrigin ?? null;
    image.referrerPolicy = referrerPolicy ?? '';
    image.src = src ?? '';

    if (srcSet) {
      image.srcset = srcSet;
    }

    return () => {
      active = false;
    };
  }, [crossOrigin, referrerPolicy, src, srcSet]);

  return loaded;
};
