import React from 'react';
import clsx from 'clsx';
import styled from 'styled-components';

import { cssBaselineClassNames } from '../../core/CssBaseline';
import { FormHelperTextRef, FormHelperTextProps } from './FormHelperText.types';

export const FormHelperTextRoot = styled.p`
  ${({ theme }) => theme.fns.getTypographyStyles('primary.r12')}
`;

export const formHelperTextClassNames = {
  root: FormHelperTextRoot.toString().slice(1),
};

export const FormHelperText = React.forwardRef<
  FormHelperTextRef,
  FormHelperTextProps
>(function FormHelperText({ className, style, children, error }, ref) {
  return (
    <FormHelperTextRoot
      data-testid="FormHelperText"
      ref={ref}
      className={clsx(error && cssBaselineClassNames.error, className)}
      style={style}
    >
      {children}
    </FormHelperTextRoot>
  );
});
