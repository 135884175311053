import React from 'react';
import styled from 'styled-components';

import { IconBase } from '../IconBase';
import { AddIconRef, AddIconProps } from './AddIcon.types';

export const AddIconRoot = styled(IconBase)``;

export const addIconClassNames = {
  root: AddIconRoot.toString().slice(1),
};

export const AddIcon = React.forwardRef<AddIconRef, AddIconProps>(
  function AddIcon({ className, style, size, color, ...otherProps }, ref) {
    return (
      <AddIconRoot
        data-testid="AddIcon"
        ref={ref}
        className={className}
        style={style}
        size={size}
        color={color}
        {...otherProps}
      >
        <path
          d="M10 1.83447V18.1678"
          strokeWidth="1.11111"
          strokeLinecap="round"
          strokeLinejoin="round"
          stroke="currentColor"
        />
        <path
          d="M1.8335 9.99951H18.1668"
          strokeWidth="1.11111"
          strokeLinecap="round"
          strokeLinejoin="round"
          stroke="currentColor"
        />
      </AddIconRoot>
    );
  },
);
