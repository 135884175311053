import React from 'react';
import styled from 'styled-components';

import { IconBase } from '../IconBase';
import { LaptopIconRef, LaptopIconProps } from './LaptopIcon.types';

export const LaptopIconRoot = styled(IconBase)``;

export const laptopIconClassNames = {
  root: LaptopIconRoot.toString().slice(1),
};

export const LaptopIcon = React.forwardRef<LaptopIconRef, LaptopIconProps>(
  function LaptopIcon({ className, style, size, color, ...otherProps }, ref) {
    return (
      <LaptopIconRoot
        data-testid="LaptopIcon"
        ref={ref}
        className={className}
        style={style}
        size={size}
        color={color}
        {...otherProps}
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M8.14706 15.0591H0.5V16.0672C0.5 16.6475 0.970401 17.1179 1.55067 17.1179H18.8611C19.4414 17.1179 19.9118 16.6475 19.9118 16.0672V15.0591H12.5588V15.3532C12.5588 15.5156 12.4271 15.6473 12.2647 15.6473H8.44117C8.27874 15.6473 8.14706 15.5156 8.14706 15.3532V15.0591Z"
          fill="currentColor"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M3.6749 3C2.57203 3 1.67798 3.89405 1.67798 4.99692V13.178C1.67798 13.7294 2.125 14.1765 2.67644 14.1765H17.7383C18.2898 14.1765 18.7368 13.7294 18.7368 13.178V4.99692C18.7368 3.89405 17.8427 3 16.7399 3H3.6749ZM16.9721 4.76471H3.44268V12.4118H16.9721V4.76471Z"
          fill="currentColor"
        />
      </LaptopIconRoot>
    );
  },
);
