import React from 'react';
import styled from 'styled-components';

import { IconBase } from '../IconBase';
import { AddCircleIconRef, AddCircleIconProps } from './AddCircleIcon.types';

export const AddCircleIconRoot = styled(IconBase)``;

export const addCircleIconClassNames = {
  root: AddCircleIconRoot.toString().slice(1),
};

export const AddCircleIcon = React.forwardRef<
  AddCircleIconRef,
  AddCircleIconProps
>(function AddCircleIcon(
  { className, style, size, color, ...otherProps },
  ref,
) {
  return (
    <AddCircleIconRoot
      data-testid="AddCircleIcon"
      ref={ref}
      className={className}
      style={style}
      size={size}
      color={color}
      {...otherProps}
    >
      <circle cx="10" cy="10" r="9.5" stroke="currentColor" />
      <path
        d="M10 5.91724V14.0839"
        strokeLinecap="round"
        strokeLinejoin="round"
        stroke="currentColor"
      />
      <path
        d="M5.91675 9.99976H14.0834"
        strokeLinecap="round"
        strokeLinejoin="round"
        stroke="currentColor"
      />
    </AddCircleIconRoot>
  );
});
