import { Breakpoints } from '../themes.types';

const MOBILE_WIDTH = 576;
const TABLET_WIDTH = 768;
const DEFAULT_WIDTH = 992;
const LARGE_DISPLAY_WIDTH = 1400;

export const breakpoints: Breakpoints = Object.assign(
  [MOBILE_WIDTH, TABLET_WIDTH, DEFAULT_WIDTH, LARGE_DISPLAY_WIDTH],
  {
    sm: MOBILE_WIDTH,
    md: TABLET_WIDTH,
    lg: DEFAULT_WIDTH,
    xl: LARGE_DISPLAY_WIDTH,
  },
);

export default breakpoints;
